import { MarketplaceComponentConfig } from '@nocode/types';

const config: MarketplaceComponentConfig = {
  name: 'BookingCalendar',
  displayName: {
    en: 'BookingCalendar',
    ja: 'BookingCalendar',
  },
  icon: './icon.png',
  defaultWidth: 375,
  defaultHeight: 400,
  resizeY: true,
  resizeX: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What events should be displayed?',
        ja: '表示するデータを選択してください',
      },
      type: 'list',
      disableListOptions: true,
    },
    {
      name: 'eventStarttime',
      displayName: {
        en: 'Event Start',
        ja: 'イベント開始日',
      },
      role: 'listItem',
      reference: 'items',
      type: 'text',
      hideFormatting: true,
    },
    {
      name: 'stockAvailable',
      displayName: {
        en: 'Stock Available',
        ja: 'Stock Available',
      },
      reference: 'items',
      type: 'number',
    },
    {
      name: 'minimumStockAvailable',
      displayName: {
        en: 'Minimum Stock Available',
        ja: 'Minimum Stock Available',
      },
      reference: 'items',
      type: 'number',
    },
    {
      name: 'timeDuration',
      displayName: {
        en: 'Time duration',
        ja: 'Time duration',
      },
      type: 'text',
      default: '30',
      control: {
        type: 'menu',
        options: [
          {
            label: '15 minutes',
            value: '15',
          },
          {
            label: '30 minutes',
            value: '30',
          },
          {
            label: '45 minutes',
            value: '45',
          },
          {
            label: '60 minutes',
            value: '60',
          },
        ],
      },
    },
    {
      name: 'dayViewNumber',
      displayName: {
        en: 'Number of day to display',
        ja: 'Number of day to display',
      },
      type: 'text',
      default: '7d',
      control: {
        type: 'menu',
        options: [
          {
            label: '3 days',
            value: '3days',
          },
          {
            label: '4 days',
            value: '4days',
          },
          {
            label: '5 days',
            value: '5days',
          },
          {
            label: '7 days',
            value: '7days',
          },
          {
            label: '1 month',
            value: '1month',
          },
        ],
      },
    },
    {
      name: 'onPress',
      displayName: {
        en: 'Event',
        ja: 'Event',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
    },
    {
      name: 'sumAvailableStock',
      displayName: {
        en: 'Sum available stock',
        ja: 'Sum available stock',
      },
      type: 'switch',
    },
    {
      name: 'sortBy',
      displayName: {
        en: 'Sort by',
        ja: 'Sort by',
      },
      type: 'text',
      control: {
        type: 'menu',
        options: [
          {
            label: 'Available stock low to high',
            value: 'availableStockAsc',
          },
          {
            label: 'Available stock high to low',
            value: 'availableStockDesc',
          },
          {
            label: 'Updated time old to new',
            value: 'updatedTimeAsc',
          },
          {
            label: 'Updated time new to old',
            value: 'updatedTimeDesc',
          },
          {
            label: 'Created time old to new',
            value: 'createdTimeAsc',
          },
          {
            label: 'Created time new to old',
            value: 'createdTimeDesc',
          },
        ],
      },
    },
  ],
  childComponents: [
    {
      name: 'customInitializeTime',
      displayName: {
        en: 'Custom current time',
        ja: 'Custom current time',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'hour',
          type: 'number',
          placeholder: 'Current hour',
          displayName: {
            en: 'Hour (24h format)',
            ja: 'Hour (24h format)',
          },
        },
        {
          name: 'minute',
          type: 'number',
          displayName: {
            en: 'Minute',
            ja: 'Minute',
          },
        },
      ],
    },
    {
      name: 'customDisplayTime',
      displayName: {
        en: 'Custom display time',
        ja: 'Custom display time',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'from',
          type: 'text',
          placeholder: 'HH:mm 24h',
          displayName: {
            en: 'From (HH:mm 24h)',
            ja: 'From (HH:mm 24h)',
          },
          disableDynamicValues: true,
        },
        {
          name: 'to',
          type: 'text',
          placeholder: 'HH:mm 24h',
          displayName: {
            en: 'To (HH:mm 24h)',
            ja: 'To (HH:mm 24h)',
          },
          disableDynamicValues: true,
        },
      ],
    },
    {
      name: 'styles',
      displayName: {
        en: 'Style',
        ja: 'Style',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'dayWidth',
          displayName: {
            en: 'Day width',
            ja: 'Day width',
          },
          type: 'number',
          conditions: {
            min: 20,
            error: 'Value must >= 20',
          },
          disableDynamicValues: true,
        },
        {
          name: 'availableColor',
          displayName: {
            en: 'Available color',
            ja: 'Available color',
          },
          type: 'color',
        },
        {
          name: 'fewAvailableColor',
          displayName: {
            en: 'Few Available color',
            ja: 'Few Available color',
          },
          type: 'color',
        },
        {
          name: 'notAvailableColor',
          displayName: {
            en: 'Not Available color',
            ja: 'Not Available color',
          },
          type: 'color',
        },
        {
          name: 'saturdayColor',
          displayName: {
            en: 'Saturday color',
            ja: 'Saturday color',
          },
          type: 'color',
        },
        {
          name: 'holidayColor',
          displayName: {
            en: 'Holiday color',
            ja: 'Holiday color',
          },
          type: 'color',
        },
      ],
    },
    // navigation props
    {
      name: 'navigation',
      displayName: {
        en: 'Navigation',
        ja: '選択日のオプション',
      },
      props: [
        {
          name: 'defDate',
          displayName: {
            en: 'Begin date',
            ja: 'Begin date',
          },
          placeholder: 'YYYY-MM-DD',
          type: 'date',
        },
        {
          name: 'minDate',
          displayName: {
            en: 'Earliest Selectable Date',
            ja: '選択可能な開始日',
          },
          type: 'date',
          default: '2019-01-01',
          placeholder: 'YYYY-MM-DD',
          hideFormatting: true,
        },
        {
          name: 'maxDate',
          displayName: {
            en: 'Latest Selectable Date',
            ja: '選択可能な終了日',
          },
          type: 'date',
          default: '2030-01-01',
          placeholder: 'YYYY-MM-DD',
          hideFormatting: true,
        },
      ],
    },
  ],
};

export default config;
