import React, {
  createContext,
  PropsWithChildren,
  Ref,
  RefObject,
  useContext,
  useRef,
  useState,
} from 'react';
import { FlatList } from 'react-native';
import {
  ChatComponentActionsType,
  ChatComponentType,
} from '@common/hooks/useChatComponentActions';
import { ChatComponentProps } from './type';

export type ChatComponentState = {
  groupLoading: boolean;
  setGroupLoading: React.Dispatch<React.SetStateAction<boolean>>;

  messageHistoryLoading: boolean;
  setMessageHistoryLoading: React.Dispatch<React.SetStateAction<boolean>>;

  messageSending: boolean;
  setMessageSending: React.Dispatch<React.SetStateAction<boolean>>;

  selectedGroup: ChatComponentType.GroupMessageTypeWithMessageJson | null;
  setSelectedGroup: (groupId: string | null) => void;

  groupMessages: ChatComponentType.GroupMessageTypeWithMessageJson[];
  setGroupMessages: React.Dispatch<
    React.SetStateAction<ChatComponentType.GroupMessageTypeWithMessageJson[]>
  >;

  unreadMessageStatus: Record<string, number>;
  setUnreadMessageStatus: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;

  messageHistories: ChatComponentType.ChatMessagesTypeWithMessageJson[];
  setMessageHistories: React.Dispatch<
    React.SetStateAction<ChatComponentType.ChatMessagesTypeWithMessageJson[]>
  >;

  messageHistoriesPagination: Pick<
    ChatComponentType.PaginationResponse,
    'limit' | 'skip' | 'total'
  >;
  setMessageHistoriesPagination: React.Dispatch<
    React.SetStateAction<
      Pick<ChatComponentType.PaginationResponse, 'limit' | 'skip' | 'total'>
    >
  >;

  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  filesValue: ChatComponentType.FileType[];
  setFilesValue: React.Dispatch<
    React.SetStateAction<ChatComponentType.FileType[]>
  >;

  selectedImage: ChatComponentType.FileType | null;
  setSelectedImage: React.Dispatch<
    React.SetStateAction<ChatComponentType.FileType | null>
  >;

  listRef: RefObject<
    FlatList<ChatComponentType.ChatMessagesTypeWithMessageJson>
  >;

  onDownloadFile: ChatComponentActionsType['onDownloadFile'];
  uploadFile: ChatComponentActionsType['uploadFile'];
  selectFile: ChatComponentActionsType['selectFile'];
  fileUploading: boolean;
};

export const ChatComponentContext = createContext<ChatComponentState>(
  {} as any
);
export const useChatComponentContext = () => useContext(ChatComponentContext);

export const ChatComponentProvider: React.FC<
  PropsWithChildren<ChatComponentProps>
> = (props) => {
  const [groupLoading, setGroupLoading] = useState(false);
  const [messageHistoryLoading, setMessageHistoryLoading] = useState(false);
  const [messageSending, setMessageSending] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [filesValue, setFilesValue] = useState<ChatComponentType.FileType[]>(
    []
  );
  const [selectedImage, setSelectedImage] =
    useState<ChatComponentType.FileType | null>(null);
  const [selectedGroup, _setSelectedGroup] =
    useState<ChatComponentType.GroupMessageTypeWithMessageJson | null>(null);
  const [groupMessages, setGroupMessages] = useState<
    ChatComponentType.GroupMessageTypeWithMessageJson[]
  >([]);
  const [unreadMessageStatus, setUnreadMessageStatus] = useState<
    Record<string, number>
  >({});
  const [messageHistories, setMessageHistories] = useState<
    ChatComponentType.ChatMessagesTypeWithMessageJson[]
  >([]);
  const [messageHistoriesPagination, setMessageHistoriesPagination] = useState<
    Pick<ChatComponentType.PaginationResponse, 'limit' | 'skip' | 'total'>
  >({ limit: 10, skip: 0, total: 0 });
  const listRef = useRef<FlatList>(null);

  const setSelectedGroup = (groupId: string | null) => {
    if (!groupId) {
      _setSelectedGroup(null);
      return;
    }
    const groupSelect = groupMessages.find((g) => g.id === groupId);
    if (groupSelect) {
      _setSelectedGroup(groupSelect);
      return;
    }
    _setSelectedGroup(null);
  };
  return (
    <ChatComponentContext.Provider
      value={{
        groupLoading,
        setGroupLoading,
        messageHistoryLoading,
        setMessageHistoryLoading,
        selectedGroup,
        setSelectedGroup,
        groupMessages,
        setGroupMessages,
        unreadMessageStatus,
        setUnreadMessageStatus,
        messageHistories,
        setMessageHistories,
        messageSending,
        setMessageSending,
        inputValue,
        setInputValue,
        filesValue,
        setFilesValue,
        selectedImage,
        setSelectedImage,
        messageHistoriesPagination,
        setMessageHistoriesPagination,
        listRef,
        onDownloadFile: props.chatActions.onDownloadFile,
        selectFile: props.chatActions.selectFile,
        uploadFile: props.chatActions.uploadFile,
        fileUploading: props.chatActions.uploading,
      }}
    >
      {props.children}
    </ChatComponentContext.Provider>
  );
};
