import {
  View,
  Text,
  Image,
  StyleSheet,
  ScrollView,
  Platform,
} from 'react-native';
import React from 'react';

export const SpHeader = ({ text }: { text: string }) => {
  return (
    <View style={styles.spHeader} nativeID="SpHeader">
      <Text style={styles.headerText}>{text}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  spHeader: {
    height: 57,
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: '#E9EFF2',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#1A2737',
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 25.2,
  },
});
