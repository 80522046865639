export default {
  name: 'ActionButton',
  displayName: {
    en: 'Action',
    ja: '動作ボタン',
  },
  icon: 'icon.png',
  defaultWidth: 56,
  defaultHeight: 56,
  props: [
    {
      name: 'nameIcon',
      displayName: {
        en: '',
        ja: '',
      },
      type: 'icon',
      nameIcon: 'add',
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'ActionButton',
        ja: 'アクション',
      },
    },
  ],
  resizeX: false,
  resizeY: false,
};
