export default {
  name: 'UnivaPaySubscription',
  displayName: {
    en: 'UnivaPay Subscription',
    ja: 'UnivaPay Subscription',
  },
  icon: './icon.png',
  defaultWidth: 330,
  defaultHeight: 210,

  props: [
    {
      namespace: 'univaPaySubscription',
      name: 'Settings',
      displayName: {
        en: 'Settings',
        ja: 'Settings',
      },
      type: 'payment',
    },
    {
      name: 'successActions',
      displayName: {
        en: 'Actions when UnivaPay Subscription is paid successful',
        ja: '決済成功時のアクショ',
      },
      type: 'action',
    },
    {
      name: 'failedActions',
      displayName: {
        en: 'Actions when UnivaPay Subscription is paid failed',
        ja: '決済失敗時のアクション',
      },
      type: 'action',
    },
  ],
  childComponents: [
    {
      name: 'email',
      displayName: {
        en: 'Email Field',
        ja: 'メールアドレス',
      },
      props: [
        {
          name: 'emailBuyer',
          displayName: {
            en: 'Buyer email',
            ja: '購入者のメールアドレス',
          },
          type: 'text',
          default: '',
        },
      ],
    },
    {
      name: 'paymentOptions',
      displayName: {
        en: 'Payment Amount',
        ja: '支払い金額',
      },
      props: [
        {
          name: 'paymentAmount',
          displayName: {
            en: 'Payment Amount',
            ja: '支払い金額',
          },
          type: 'number',
          default: 0,
        },
        {
          name: 'typeCurrency',
          displayName: {
            en: 'Currency Type',
            ja: '通貨',
          },
          type: 'text',
          default: 'jpy',
          control: {
            type: 'menu',
            options: [
              {
                label: 'JPY',
                value: 'jpy',
              },
              {
                label: 'USD',
                value: 'usd',
              },
            ],
          },
        },
        {
          name: 'period',
          displayName: {
            en: 'Period',
            ja: 'Period',
          },
          type: 'text',
          default: 'daily',
          control: {
            type: 'menu',
            options: [
              {
                label: 'Daily',
                value: 'daily',
              },
              {
                label: 'Weekly',
                value: 'weekly',
              },
              {
                label: 'Biweekly',
                value: 'biweekly',
              },
              {
                label: 'Monthly',
                value: 'monthly',
              },
              {
                label: 'Yearly',
                value: 'annually',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'titleCardName',
      displayName: {
        en: 'Title Card Name',
        ja: 'タイトル Card Name',
      },
      // type: 'text',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Card number information',
        },
        {
          name: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#000000',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '#fff',
          enabled: {
            background: true,
          },
        },
        {
          name: 'fontSize',
        },
        {
          name: 'titleLineNum',
        },
        {
          name: 'fontStyleItalic',
        },
        {
          name: 'textDecorationUnderline',
        },
        {
          name: 'textDecorationLineThrough',
        },
        {
          name: 'fontWeightBold',
        },
      ],
    },
    {
      name: 'titleCardNumber',
      displayName: {
        en: 'Title Card Number',
        ja: 'タイトル Card Number',
      },
      // type: 'text',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Card number information',
        },
        {
          name: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#000000',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '#fff',
          enabled: {
            background: true,
          },
        },
        {
          name: 'fontSize',
        },
        {
          name: 'titleLineNum',
        },
        {
          name: 'fontStyleItalic',
        },
        {
          name: 'textDecorationUnderline',
        },
        {
          name: 'textDecorationLineThrough',
        },
        {
          name: 'fontWeightBold',
        },
      ],
    },
    {
      name: 'submitButton',
      displayName: {
        en: 'Submit Button',
        ja: '送信ボタン',
      },
      // type: 'text',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Pay Now',
        },
        {
          name: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'fontSize',
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#fff',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '#34A0DD',
        },
        {
          name: 'borderRadius',
          default: 100,
        },
      ],
    },
  ],
};
