import axiosInstance from '@common/services/axiosIntance/axiosService';

export const useMapActions = () => {
  const postAddressMarker = async (requestBody: any) => {
    const response = await axiosInstance({
      method: 'post',
      url: '/maps',
      data: requestBody,
    });
    return response.data as { lat: number; lng: number }[];
  };

  return {
    postAddressMarker,
  };
};
export type MapActionsType = ReturnType<typeof useMapActions>;
