const EnDateTimeOffsets = [
  [-365, '1 year ago'],
  [-180, '6 months ago'],
  [-90, '90 days ago'],
  [-60, '60 days ago'],
  [-30, '30 days ago'],
  [-14, '2 weeks ago'],
  [-7, '1 week ago'],
  [-6, '6 days ago'],
  [-5, '5 days ago'],
  [-4, '4 days ago'],
  [-3, '3 days ago'],
  [-2, '2 days ago'],
  [-1, '1 day ago'],
  null,
  [-0.5, '12 hours ago'],
  [-0.25, '6 hours ago'],
  [-0.125, '3 hours ago'],
  [-0.083333333333333, '2 hours ago'],
  [-0.041666666666666, '1 hour ago'],
  [-0.020833333333333, '30 minutes ago'],
  [-0.010416666666666, '15 minutes ago'],
  [-0.003472222222222, '5 minutes ago'],
  [-0.000694444444444, '1 minute ago'],
  null,
  [0.00069444, '1 minute from now'],
  [0.003472222222222, '5 minutes from now'],
  [0.010416666666666, '15 minutes from now'],
  [0.020833333333333, '30 minutes from now'],
  [0.041666666666666, '1 hour from now'],
  [0.083333333333333, '2 hours from now'],
  [0.125, '3 hours from now'],
  [0.25, '6 hours from now'],
  [0.5, '12 hours from now'],
  null,
  [1, 'Tomorrow'],
  [2, '2 days from now'],
  [3, '3 days from now'],
  [4, '4 days from now'],
  [5, '5 days from now'],
  [6, '6 days from now'],
  [7, '1 week from now'],
  [14, '2 weeks from now'],
  [30, '30 days from now'],
  [60, '60 days from now'],
  [90, '90 days from now'],
  [180, '6 months from now'],
  [365, '1 year from now'],
];

const JaDateTimeOffsets = [
  [-365, '１年前'],
  [-180, '６ヶ月前'],
  [-90, '９０日前'],
  [-60, '６０日前'],
  [-30, '３０日前'],
  [-14, '２週間前'],
  [-7, '１週間前'],
  [-6, '６日前'],
  [-5, '５日前'],
  [-4, '４日前'],
  [-3, '３日前'],
  [-2, '２日前'],
  [-1, '１日前'],
  null,
  [-0.5, '１２時間前'],
  [-0.25, '６時間前'],
  [-0.125, '３時間前'],
  [-0.083333333333333, '２時間前'],
  [-0.041666666666666, '１時間前'],
  [-0.020833333333333, '３０分前'],
  [-0.010416666666666, '１５分前'],
  [-0.003472222222222, '５分前'],
  [-0.000694444444444, '１分前'],
  null,
  [0.000694444444444, '１分後'],
  [0.003472222222222, '５分後'],
  [0.010416666666666, '１５分後'],
  [0.020833333333333, '３０分後'],
  [0.041666666666666, '１時間後'],
  [0.083333333333333, '２時間後'],
  [0.125, '３時間後'],
  [0.25, '６時間後'],
  [0.5, '１２時間後'],
  null,
  [1, '明日'],
  [2, '２日後'],
  [3, '３日後'],
  [4, '４日後'],
  [5, '５日後'],
  [6, '６日後'],
  [7, '１週間後'],
  [14, '２週間後'],
  [30, '３０日後'],
  [60, '６０日後'],
  [90, '９０日後'],
  [180, '６ヶ月後'],
  [365, '１年後'],
];
/**
 *
 * @param {number} source.offset unit: day
 * @param {boolean} source.startOfDay
 * @param selectedLanguage
 * @returns
 */
export const getDateTimeRelative = (
  source: { offset?: number },
  selectedLanguage?: 'en' | 'ja'
) => {
  let { offset } = source;

  if (!offset) {
    return 'Now';
  }
  const offsetList =
    selectedLanguage === 'en' ? EnDateTimeOffsets : JaDateTimeOffsets;
  for (const i in offsetList) {
    const value = offsetList[i];
    if (value) {
      const [offsetDayValue, offsetTitle] = value;
      if (offset < +offsetDayValue) {
        return offsetTitle;
      }
    }
  }
  // return latest value in offsetList when offset > 365 days
  return offsetList[offsetList.length - 1]?.[1] || '';
};
