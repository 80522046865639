import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface FileAttachIconProps {
  size?: number;
  color?: string;
}

const FileAttachIcon: React.FC<FileAttachIconProps> = ({
  size = 24,
  color = '#6C777C',
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M16.5 6.5L8.5 14.5C7.67 15.33 7.67 16.67 8.5 17.5C9.33 18.33 10.67 18.33 11.5 17.5L18 11C19.66 9.34 19.66 6.66 18 5C16.34 3.34 13.66 3.34 12 5L5 12C2.79 14.21 2.79 17.79 5 20C7.21 22.21 10.79 22.21 13 20L21 12"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default FileAttachIcon;
