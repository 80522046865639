import { IBookingCalendar } from '@nocode/types';
import {
  BindingItemType,
  BindingItemTypeFormatted,
  BookingCalendarHolidayResponse,
} from '@nocode/types/bookingCalendar.type';
import { get } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, Platform } from 'react-native';
import {
  getActions,
  getItemListClick,
  getValueBinding,
  getValueBindingComponent,
  ONE_DAY,
} from '../shared';

const TOTAL_MINUTE_IN_A_DAY = 1440; // 24*60
export const RATIO_TEXT_CONTENT = 0.8;
type BindingValue = {
  timeDuration: string;
  'customInitializeTime.enabled': boolean;
  'customInitializeTime.hour': string | number;
  'customInitializeTime.minute': string | number;
  'navigation.defDate': string;
  'navigation.maxDate': string;
  'navigation.minDate': string;
};

const parseMinuteToTimeLabel = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const minute = minutes - hours * 60;
  return `${hours.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')}`;
};

/**
 * 00:00 -> 0
 *
 * 00:30 -> 30
 * ...
 * 23:00 -> 1410
 *
 * invalid -> null
 */
function getMinuteFromDisplayTime(timeStr: string | undefined): number | null {
  if (!timeStr) {
    return null;
  }
  try {
    const [HH, mm] = timeStr.split(':');
    const hour = Number(HH);
    const minute = Number(mm);
    if (Number.isNaN(hour) || hour > 23 || hour < 0) {
      return null;
    }
    if (Number.isNaN(minute) || minute > 60 || minute < 0) {
      return null;
    }
    return hour * 60 + minute;
  } catch (_) {
    return null;
  }
}

/**
 * @param {string} timeString hh:mm
 * @param {number} durationMinute 30
 */
const getIndexFromTimeString = ({
  durationMinute,
  timeString,
  displayTime,
}: {
  timeString: string;
  durationMinute: number;
  displayTime: { from: number };
}) => {
  const minutes = getMinuteFromDisplayTime(timeString) || 0;
  return Math.floor((minutes - displayTime.from) / durationMinute);
};

const getMockData = (
  durationMinute: number,
  displayTime: { from: number }
): BindingItemType[] => {
  const currentTime = moment();
  return [
    {
      id: `available`,
      stockAvailable: '2',
      minimumStockAvailable: '1',
      eventStarttime: currentTime
        .startOf('day')
        .add(displayTime.from, 'minutes')
        .add(1, 'day')
        .toISOString(),
      createdAt: currentTime.toISOString(),
      updatedAt: currentTime.toISOString(),
    },
    {
      id: `fewAvailable`,
      stockAvailable: '1',
      minimumStockAvailable: '1',
      eventStarttime: currentTime
        .startOf('day')
        .add(displayTime.from, 'minutes')
        .add(durationMinute, 'minutes')
        .add(2, 'day')
        .toISOString(),
      createdAt: currentTime.toISOString(),
      updatedAt: currentTime.toISOString(),
    },
    {
      id: `notAvailable`,
      stockAvailable: '0',
      minimumStockAvailable: '1',
      eventStarttime: currentTime
        .startOf('day')
        .add(displayTime.from, 'minutes')
        .add(durationMinute * 2, 'minutes')
        .add(3, 'day')
        .toISOString(),
      createdAt: currentTime.toISOString(),
      updatedAt: currentTime.toISOString(),
    },
  ];
};

const getJpHoliday = async (): Promise<BookingCalendarHolidayResponse> => {
  const url = 'https://holidays-jp.github.io/api/v1/date.json';
  return fetch(url).then((r) => {
    if (r.ok) {
      return r.json();
    }
    return {};
  });
};

export const useBookingCalendar = (props: IBookingCalendar) => {
  const [beginDate, setBeginDate] = useState(moment().startOf('day').toDate());
  const [initializeTimeIndex, setInitializeTimeIndex] = useState(1);
  const [holidays, setHolidays] = useState<BookingCalendarHolidayResponse>();
  const leftRef = useRef<FlatList<string>>(null);
  const rightRef = useRef<FlatList<string>>(null);
  const { onPress, dataBinding = [], attributes } = props;
  const {
    timeDuration = '30',
    initializeList,
    dayViewNumber = '7days',
    styles: customStyles,
    customDisplayTime,
  } = attributes;
  const isCanvas =
    Platform.OS === 'web' &&
    !queryString.parse(window?.location?.search)?.target;
  const durationMinute = +timeDuration;

  const displayTime = useMemo(
    () => ({
      from: customDisplayTime?.enabled
        ? getMinuteFromDisplayTime(customDisplayTime.from) ?? 0
        : 0,
      to: customDisplayTime?.enabled
        ? getMinuteFromDisplayTime(customDisplayTime.to) ??
          TOTAL_MINUTE_IN_A_DAY
        : TOTAL_MINUTE_IN_A_DAY,
    }),
    [customDisplayTime]
  );

  const getInitValues = useCallback((): BindingItemType[] => {
    if (Platform.OS !== 'web') {
      // App
      return dataBinding;
    }
    // web
    if (isCanvas) {
      // return mock data on canvas
      return getMockData(durationMinute, displayTime);
    }
    // preview
    return dataBinding;
  }, [dataBinding, displayTime]);

  const bindingValue = useMemo(
    () =>
      getValueBinding(
        props.id,
        props.data || dataBinding[0] || {},
        props
      ) as BindingValue,
    [props.id, props.data, dataBinding]
  );

  const rawData = useMemo(
    () => getInitValues().filter((e) => moment(e.eventStarttime).isValid()),
    [getInitValues]
  );
  const data = useMemo(
    () =>
      rawData
        .map<BindingItemTypeFormatted>((item) => ({
          createdAt: new Date(item.createdAt),
          eventStarttime: new Date(item.eventStarttime),
          id: item.id,
          minimumStockAvailable: Number(item.minimumStockAvailable) || 0,
          stockAvailable: Number(item.stockAvailable) || 0,
          updatedAt: new Date(item.updatedAt),
        }))
        .sort((a, b) => moment(a.eventStarttime).diff(b.eventStarttime)),
    [rawData]
  );

  const numberOfDaysDisplayed = useMemo(() => {
    switch (dayViewNumber) {
      case '3days':
        return 3;
      case '4days':
        return 4;
      case '5days':
        return 5;
      case '7days':
        return 7;
      case '1month':
        if (isCanvas && !customStyles.enabled) {
          return 7;
        }
        return moment(beginDate).daysInMonth();
    }
  }, [dayViewNumber, beginDate]);

  const itemDisplay = useMemo(() => {
    return data.filter((item) =>
      moment(item.eventStarttime).isBetween(
        moment(beginDate),
        moment(beginDate).add(numberOfDaysDisplayed, 'days')
      )
    );
  }, [data, beginDate, numberOfDaysDisplayed]);

  /**
   * Example:
   [
   '00:00': {'2024-10-23': [], ..., '2024-10-30': []},
   '00:30': {'2024-10-23': [], ..., '2024-10-30': []},
   ...
   '11:30': {'2024-10-23': [], ..., '2024-10-30': []},
   '12:00': {'2024-10-23': [], ..., '2024-10-30': []},
   '12:30': {'2024-10-23': [], ..., '2024-10-30': []},
   ...
   '23:30': {'2024-10-23': [], ..., '2024-10-30': []},
   ]
   */
  const durationMapping = useMemo(() => {
    const result: Record<
      string,
      Record<string, BindingItemTypeFormatted[]>
    > = {};
    const getInitDurationMappingItem = (): Record<
      string,
      BindingItemTypeFormatted[]
    > =>
      Array(numberOfDaysDisplayed)
        .fill(0)
        .map((_, i) => moment(beginDate).add(i, 'day').format('YYYY-MM-DD'))
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr]: [],
          };
        }, {});
    if (isCanvas) {
      // render maximum 20 row items on canvas
      const maximumMinute = Math.min(
        20 * durationMinute + displayTime.from,
        displayTime.to
      );
      for (
        let minuteRun = displayTime.from;
        minuteRun < maximumMinute;
        minuteRun += durationMinute
      ) {
        result[parseMinuteToTimeLabel(minuteRun)] =
          getInitDurationMappingItem();
      }
    } else {
      for (
        let minuteRun = displayTime.from;
        minuteRun < displayTime.to;
        minuteRun += durationMinute
      ) {
        result[parseMinuteToTimeLabel(minuteRun)] =
          getInitDurationMappingItem();
      }
    }
    const durationArrayKeys = Object.keys(result);
    itemDisplay.forEach((item) => {
      const timeString = moment(item.eventStarttime).format('HH:mm');
      const index = getIndexFromTimeString({
        timeString,
        durationMinute,
        displayTime,
      });
      const durationArrayKey = durationArrayKeys[index];
      const date = moment(item.eventStarttime).format('YYYY-MM-DD');
      result[durationArrayKey]?.[date]?.push(item);
    });
    return result;
  }, [itemDisplay, numberOfDaysDisplayed, displayTime]);

  const onNextPage = useCallback(() => {
    setBeginDate((old) => {
      const [value, unit] = dayViewNumber.match(/\d+|\D+/g) ?? [7, 'days'];

      let nextBeginDate = moment(old).add(value, unit as any);
      return nextBeginDate.toDate();
    });
  }, [dayViewNumber]);

  const onPreviousPage = useCallback(() => {
    setBeginDate((old) => {
      const [value, unit] = dayViewNumber.match(/\d+|\D+/g) ?? [7, 'days'];

      let nextBeginDate = moment(old).subtract(value, unit as any);
      return nextBeginDate.toDate();
    });
  }, [dayViewNumber]);

  const handlePress = useCallback(
    (id: string) => {
      const item = getInitValues().find((e) => e.id === id);
      if (item) {
        const options = {
          itemListClick: getItemListClick(item),
        };

        onPress && onPress(getActions(props, id), options);
      }
    },
    [getInitValues]
  );

  const getDefDateFromFormula = useCallback(() => {
    const bindingId = get(
      attributes,
      'navigation.defDate.parsedFormula.source.objectId'
    );
    return get(props.dependencies, `valueInputs.${bindingId}`);
  }, [props.dependencies]);

  // set begin date
  useEffect(() => {
    if (isCanvas) {
      return;
    }
    const defDate = get(bindingValue, 'navigation.defDate');
    if (moment(defDate).isValid()) {
      setBeginDate(moment(defDate).toDate());
      return;
    }
    if (Number(defDate)) {
      setBeginDate(new Date(ONE_DAY * Number(defDate)));
      return;
    }
    const defDateFormula = getDefDateFromFormula();
    if (moment(defDateFormula).isValid()) {
      setBeginDate(moment(defDateFormula).toDate());
      return;
    }
  }, [bindingValue, getDefDateFromFormula]);

  // update initialize time
  useEffect(() => {
    if (isCanvas) {
      return;
    }
    const currentTime = moment();
    let initializeTime = currentTime.format('HH:mm');
    if (bindingValue['customInitializeTime.enabled']) {
      // const hour = get(bindingValue, 'customInitializeTime.hour');

      const hour =
        get(bindingValue, 'customInitializeTime.hour') ||
        getValueBindingComponent(attributes, 'customInitializeTime.hour') ||
        get(attributes, 'customInitializeTime.hour.parsedFormula') ||
        currentTime.format('HH');
      const minute =
        get(bindingValue, 'customInitializeTime.minute') ||
        getValueBindingComponent(attributes, 'customInitializeTime.minute') ||
        get(attributes, 'customInitializeTime.minute.parsedFormula') ||
        currentTime.format('mm');
      const hourStr =
        hour.toString().match(/\d+/) && Number(hour) >= 0 && Number(hour) < 24
          ? Number(hour).toString().padStart(2, '0')
          : currentTime.format('HH');
      const minuteStr =
        minute.toString().match(/\d+/) &&
        Number(minute) >= 0 &&
        Number(minute) <= 60
          ? Number(minute).toString().padStart(2, '0')
          : currentTime.format('mm');
      initializeTime = `${hourStr}:${minuteStr}`;
    }
    const index = getIndexFromTimeString({
      durationMinute,
      timeString: initializeTime,
      displayTime,
    });
    setInitializeTimeIndex(index);
  }, [bindingValue, displayTime]);

  // scroll to current time
  useEffect(() => {
    if (!isCanvas && !initializeList && rightRef?.current && leftRef.current) {
      setTimeout(() => {
        const offset =
          initializeTimeIndex * attributes.lineHeight * RATIO_TEXT_CONTENT;
        leftRef.current?.scrollToOffset({
          animated: false,
          offset,
        });
        // rightRef will be sync with left.
      }, 300);
    }
  }, [initializeList, initializeTimeIndex, rightRef?.current, leftRef.current]);

  // get holidays
  useEffect(() => {
    getJpHoliday().then((r) => setHolidays(r));
  }, []);

  return {
    durationMapping,
    beginDate,
    onPreviousPage,
    onNextPage,
    bindingValue,
    handlePress,
    initializeTimeIndex,
    holidays,
    numberOfDaysDisplayed,
    rightRef,
    leftRef,
  };
};
