import { IMessage } from '@common/hooks/useGptConversation';
import { get, pick } from 'lodash';
import React from 'react';
import { ScrollView, Text, TextInput, View } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import { checkStyles } from '../func';
import {
  IGptConversation,
  useGptConversation,
  UseGptConversation,
} from './hook';
import createStyles from './style';

const GptConversation: React.FC<UseGptConversation> = (props) => {
  const {
    bindingValue,
    attributes: {
      sendButton: { icon },
    },
    setQuery,
    query,
    messages,
    aiRespondingText,
    onSubmit,
    aiResponding,
    messageRef,
    initializing,
  } = props;
  const username =
    get(bindingValue, 'advanceSetting.username')?.toString() || 'あなた';
  const aiName =
    get(bindingValue, 'advanceSetting.aiName')?.toString() || 'Jack';
  const submitButtonText =
    get(bindingValue, 'sendButton.text')?.toString() || '質問する';
  const placeholderText = get(bindingValue, 'placeholder')?.toString();

  const styles = createStyles(props);

  const getSenderName = (senderType: IMessage['senderType']) => {
    switch (senderType) {
      case 'user':
        return `${username}`;
      case 'ai':
      case 'error':
        return `${aiName}`;
    }
  };
  const getSenderStyle = (senderType: IMessage['senderType']) => {
    switch (senderType) {
      case 'user':
        return checkStyles({
          color: props.attributes.advanceSetting.userColor,
        });
      case 'ai':
      case 'error':
        return checkStyles({ color: props.attributes.advanceSetting.aiColor });
    }
  };

  const renderMessage = (message: IMessage, isLastedMessage?: boolean) => {
    return (
      <Text
        key={message.id}
        style={[
          styles.text,
          pick(getSenderStyle(message.senderType), ['color']),
          isLastedMessage
            ? {
                marginBottom: 16,
              }
            : {},
        ]}
      >
        {getSenderName(message.senderType)}: {message.message}
      </Text>
    );
  };

  return (
    <View style={styles.container}>
      <ScrollView
        ref={(ref) => {
          messageRef.current = ref;
        }}
        style={[styles.scrollContainer]}
      >
        {messages.map((message, index) => {
          return renderMessage(message, index === messages.length - 1);
        })}
        {aiRespondingText ? (
          renderMessage(
            {
              id: '',
              message: aiRespondingText || '',
              messageType: 'text',
              senderType: 'ai',
              createdAt: Math.round(Date.now() / 1000),
            },
            true
          )
        ) : (
          <></>
        )}
      </ScrollView>
      <View style={[styles.buttonInputContainer]}>
        <TextInput
          value={query}
          onChangeText={(text) => {
            setQuery(text);
          }}
          placeholder={placeholderText}
          placeholderTextColor={'rgb(189, 189, 189)'}
          style={styles.input}
          onSubmitEditing={() => {
            onSubmit();
          }}
        />
        <RNButton
          onPress={() => {
            onSubmit();
          }}
          icon={icon}
          disabled={!query || aiResponding || initializing}
          style={[styles.btn]}
          loading={aiResponding}
          labelStyle={styles.btnText}
        >
          {submitButtonText}
        </RNButton>
      </View>
    </View>
  );
};

const GptConversationWrapper = (props: IGptConversation) => {
  return <GptConversation {...useGptConversation(props)} />;
};

export default GptConversationWrapper;
