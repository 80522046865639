import { formControlType, listItemKey } from '@common/constants/shared';
import store from '@common/redux/store';
import {
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  isArray,
  isEmpty,
  omit,
  uniq,
} from 'lodash';
import { getActions } from './handleActions/func/helps';

export const flattenObj: any = (data: any) => {
  let result = [];

  while (data) {
    result.push(omit(data, 'source'));
    data = data.source;
  }

  return result.reverse();
};

const singleQuote = (text: string) => `${text}`;

export const flattedSourceString = (source: string | any): any => {
  if (typeof source === 'string') return source;

  if (isArray(source)) {
    // return source.filter((item: any) => typeof item === 'string').join('');

    const arrayString = filter(
      source,
      (item) => typeof item === 'string' && !isEmpty(item)
    );

    return arrayString
      .map((source) => {
        const regex = new RegExp(/^.*(\\{2})*\n$/);
        const value = `${source ?? ''}`;
        return singleQuote(
          regex.test(value) ? value.replace(/\n$/, '') : value
        );
      })
      .join(' || ');
  }

  if (typeof source === 'object' && !isArray(source)) {
    if (source?.imageType) {
      if (source.imageType === 'imageUrl')
        return flattedSourceString(source?.imageUrl);
      return flattedSourceString(source?.imageUploaded);
    }
    return null;
  }
  return null;
};

const deleteByPath = (obj: any, path: any) => {
  const _obj = JSON.parse(JSON.stringify(obj));
  const keys = path.split('.');

  keys.reduce((acc: any, key: any, index: number) => {
    if (index === keys.length - 1) {
      delete acc[key];
      return true;
    }
    return acc[key];
  }, _obj);

  return _obj;
};

const removeRelativeFormat = (obj: Record<any, any>) => {
  if (obj?.source?.format?.type === 'relative')
    return deleteByPath(obj, 'source.format');
  return obj;
};

export const getSourceBindingPath = (
  obj: any,
  path: any,
  type: 'simple' | 'list'
) => {
  if (isEmpty(path)) return {};
  if (typeof path === 'string') {
    return { [path]: get(obj, path) };
  }
  return Object.keys(path).reduce((acc, k) => {
    if (listItemKey.includes(k)) {
      return Object.keys(path[k]).reduce((acc, curr) => {
        const keyValue = path[k];
        return { ...acc, [curr]: get(obj, keyValue[curr]) };
      }, {});
    } else {
      const keyValue = get(obj, path[k]);
      const value =
        typeof obj === 'object' && !isArray(obj)
          ? removeRelativeFormat(keyValue)
          : keyValue;

      const key = k
        .split('.')
        .filter((value) => !['attributes', 'binding'].includes(value))
        .join('.');

      return {
        ...acc,
        ...(!isEmpty(value)
          ? { [type === 'simple' ? `${obj.id}.${key}` : key]: value }
          : {}),
      };
    }
  }, {});
};

export const eachRecursiveInput = (obj: any) => {
  let resp: any = [];

  const cloneObj = {
    ...cloneDeep(obj),
    actions: isEmpty(obj?.actions) ? [] : getActions(obj.actions),
  };
  const objKeys = Object.keys(cloneObj);

  forEach(objKeys, (key) => {
    const keyValue = cloneObj[key];

    // Component in Form
    if (formControlType.includes(keyValue)) resp.push(obj.id);

    // Input && Dropdown
    if (
      ['input', 'SELECT_VALUE_SELECTOR'].includes(keyValue?.type) &&
      (keyValue?.objectId || keyValue?.selectObjectId)
    ) {
      resp.push(keyValue?.objectId || keyValue?.selectObjectId);
    }

    if (typeof keyValue === 'object' && !isEmpty(keyValue)) {
      if (Array.isArray(keyValue)) {
        keyValue.map((item) => {
          resp.push(eachRecursiveInput(item));
        });
      } else {
        resp.push(eachRecursiveInput(keyValue));
      }
    }
  });

  const value = uniq((resp.flat() || []).filter((item: any) => item));

  return [...value, ...(obj?.id ? [obj?.id] : [])];
};

const getInputMetadata = (
  metadata: Record<string, any>[] = [],
  componentId: string
) => {
  const newCOmponent = metadata.filter((item) => item?.id !== componentId);
  return newCOmponent.reduce((pre: Record<string, any>[], component) => {
    const str = JSON.stringify(component);
    return [...pre, ...(str.includes(componentId) ? [component] : [])];
  }, []);
};

export const getInputDependencies = (props: Record<string, any>) => {
  if (!props?.screenUuid) return;
  const state = store.getState();
  const pageInfo = state.pageInfo.pages[props?.screenUuid];
  return getInputMetadata(pageInfo?.metadata, props.id);
};
