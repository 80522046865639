import axiosInstance from '@common/services/axiosIntance/axiosService';
import { downloadFile } from '@common/utils/handleActions/ActionItem';
import { Platform } from 'react-native';
import {
  generateFile,
  ImagePicker,
  uploadFileDocument,
} from '@common/utils/upload';
import { useState } from 'react';
import DocumentPicker from 'react-native-document-picker';

export namespace ChatComponentType {
  export type PaginationResponse<T = any> = {
    skip: number;
    limit: number;
    total: number;
    data: T[];
  };
  export type FileType = {
    url: string;
    filename: string;
    type: string;
    size?: number;
  };
  export type MessageType = {
    text?: string;
    files?: FileType[];
  };
  type GroupMessageItem = {
    message: string; // JSON format
    senderAvatar: {
      url: string;
    };
    senderId: string;
    senderName: string;
    createdAt: string | Date;
  };
  export type GroupMessageType = {
    id: string;
    groupName?: string;
    latestMessage: GroupMessageItem | null;
  };

  export type GroupMessageTypeWithMessageJson = Omit<
    GroupMessageType,
    'latestMessage'
  > & {
    latestMessage:
      | (Omit<GroupMessageItem, 'message'> & {
          message: MessageType;
        })
      | null;
  };
  export type ChatMessagesType = {
    id: string;
    message: string; // JSON format
    senderAvatar: {
      url: string;
    };
    senderId: string;
    senderName: string;
    createdAt: string | Date;
    groupId: string;
  };
  export type ChatMessagesTypeWithMessageJson = Omit<
    ChatMessagesType,
    'message'
  > & {
    message: MessageType;
  };
  export type GroupMessageUnreadStatus = {
    groupId: string;
    unreadCount: number;
  };
}
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(() => resolve(1), ms));

const parseMessageTextToJson = (
  message: any
): ChatComponentType.MessageType => {
  try {
    if (typeof message === 'string') {
      return JSON.parse(message);
    }
    return message as ChatComponentType.MessageType;
  } catch (e) {
    return {
      text: message.toString(),
      files: [],
    };
  }
};

export const useChatComponentActions = ({ appId }: { appId: string }) => {
  const [uploading, setUploading] = useState(false);
  const [uploadFileError, setUploadFileError] = useState<string>();
  const [fileUploaded, setFileUploaded] =
    useState<ChatComponentType.FileType>();

  const getUnreadStatus = async (groupIds: string[]) => {
    const res = await axiosInstance({
      method: 'GET',
      url: '/chat-component/unread-status',
      params: {
        appId,
        groupIds,
      },
    }).then((r) => r.data as ChatComponentType.GroupMessageUnreadStatus[]);
    return res;
  };

  const makeReadStatus = async (data: { messageId: string }) => {
    await axiosInstance({
      method: 'POST',
      url: '/chat-component/unread-status',
      params: {
        appId,
      },
      data,
    });
  };

  const getGroupMessages = async (): Promise<
    ChatComponentType.GroupMessageTypeWithMessageJson[]
  > => {
    const res = await axiosInstance({
      method: 'GET',
      url: '/chat-component/group',
      params: {
        appId,
      },
    })
      .then((r) => r.data as ChatComponentType.GroupMessageType[])
      .then((r) =>
        r.map((e) => ({
          ...e,
          latestMessage: e.latestMessage
            ? {
                ...e.latestMessage,
                message: parseMessageTextToJson(e.latestMessage?.message),
              }
            : null,
        }))
      );
    return res;
  };

  const getMessageHistories = async (data: {
    pagination: { skip: number; limit: number };
    groupId: string;
  }): Promise<
    ChatComponentType.PaginationResponse<ChatComponentType.ChatMessagesTypeWithMessageJson>
  > => {
    const res = await axiosInstance({
      method: 'GET',
      url: '/chat-component/messages',
      params: {
        appId,
        groupId: data.groupId,
        skip: data.pagination.skip,
        limit: data.pagination.limit,
      },
    })
      .then(
        (r) =>
          r.data as ChatComponentType.PaginationResponse<ChatComponentType.ChatMessagesType>
      )
      .then((r) => {
        return {
          ...r,
          data: r.data.map((e) => ({
            ...e,
            message: parseMessageTextToJson(e.message),
          })),
        };
      });
    return res;
  };

  const sendMessage = async (data: {
    message: ChatComponentType.MessageType;
    groupId: string;
  }): Promise<ChatComponentType.ChatMessagesTypeWithMessageJson> => {
    const res = await axiosInstance({
      method: 'POST',
      url: '/chat-component/messages',
      params: {
        appId,
      },
      data,
    })
      .then((r) => r.data as ChatComponentType.ChatMessagesType)
      .then((r) => ({ ...r, message: parseMessageTextToJson(r.message) }));
    return res;
  };

  const onDownloadFile = (
    file: Pick<ChatComponentType.FileType, 'filename' | 'url'>
  ) => {
    return downloadFile({
      response: {
        url: file.url,
        fileName: file.filename,
      },
    });
  };

  /**
   * @param options
   */
  const selectFile = async (options?: { imageOnly?: boolean }) => {
    const { imageOnly = false } = options || {};
    try {
      setUploading?.(true);
      if (Platform.OS !== 'web') {
        const res = await DocumentPicker.pickMultiple({
          type: [
            imageOnly
              ? DocumentPicker.types.images
              : DocumentPicker.types.allFiles,
          ],
        });
        const file: any = await generateFile(res[0].uri);

        const response = await uploadFileDocument(file);

        setUploading(false);
        const result: ChatComponentType.FileType = {
          filename: response.filename,
          url: response.url,
          size: response.size,
          type: response.type,
        };
        setFileUploaded(result);
        return result;
      }

      const result = await new Promise<ChatComponentType.FileType>(
        (resolve, reject) => {
          ImagePicker.showImagePicker(
            {
              quality: 0.7,
              setPending: setUploading,
              setError: setUploadFileError,
              imageUpload: imageOnly,
              imageCompress: imageOnly,
            },
            (response: any) => {
              if (response) {
                const result = {
                  filename: response.filename,
                  id: response.blobId,
                  url: response.url,
                  size: response.size,
                  type: response.type,
                };
                return resolve(result);
              }
            }
          );
        }
      );
      if (result) {
        setUploading(false);
        setFileUploaded(result);
        return result;
      }
    } catch (err) {
      console.error('===error when select file', err);
      setUploading(false);
    }
  };

  /**
   * @param file
   * @returns
   */
  const uploadFile = async (file: { uri: string }) => {
    try {
      setUploading?.(true);
      const fileBlob: any = await generateFile(file.uri);
      const response = await uploadFileDocument(fileBlob);
      setUploading(false);
      const result: ChatComponentType.FileType = {
        filename: response.filename,
        url: response.url,
        size: response.size,
        type: response.type,
      };
      setFileUploaded(result);
      return result;
    } catch (err) {
      setUploading(false);
    }
  };

  return {
    uploading,
    fileUploaded,
    uploadFileError,
    getGroupMessages,
    getUnreadStatus,
    makeReadStatus,
    getMessageHistories,
    sendMessage,
    onDownloadFile,
    uploadFile,
    selectFile,
  };
};
export type ChatComponentActionsType = ReturnType<
  typeof useChatComponentActions
>;
