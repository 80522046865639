export default {
  name: 'TrustdockLogin',
  displayName: {
    en: 'Trustdock Login',
    ja: 'Trustdockでログイン',
  },
  defaultWidth: 160,
  defaultHeight: 36,
  resizeX: true,
  props: [
    {
      name: 'type',
      displayName: {
        en: 'Type',
        ja: 'タイプ',
      },
      type: 'text',
      default: 'contained',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.button.text.outline',
            value: 'outlined',
          },
          {
            label: 'canvas.menuRight.elementTab.button.text.button',
            value: 'contained',
          },
        ],
      },
    },
    {
      name: 'text',
      displayName: {
        en: 'Text',
        ja: 'テキスト',
      },
      type: 'text',
      default: 'Button',
      styles: {
        fontFamily: '@body',
        fontWeight: '600',
      },
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'Button',
        ja: 'アクション',
      },
    },
  ],
};
