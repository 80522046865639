export default {
  name: 'BarCode',
  displayName: { ja: 'バーコード生成', en: 'Barcode' },
  resizeX: true,
  props: [
    {
      name: 'value',
      displayName: {
        ja: 'バーコードの値',
        en: 'Barcode value',
      },
      type: 'number',
      disableDynamicValues: false,
      helpText: 'Barcode value...',
      canvas: {
        onlyNumber: true,
        // default: '123456789',
      },
      // hideCollection: true,
      // hideFormInputs: true,
      // isOnlyNumberField: true,
      // regexReplace: /[^A-Z0-9\s\-\.\$\/\+\%]+/g,
      // isUpperCase: true,
    },
    {
      name: 'onPress',
      type: 'action',
      displayName: {
        en: 'Click Actions',
        ja: 'Click Actions',
      },
    },
  ],
  resizeY: true,
};
