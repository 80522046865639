import { TorusLoginProvider } from '@nocode/types/torus.type';

export const optionsTorusLogin: Array<{
  label: string;
  value: TorusLoginProvider;
}> = [
  {
    label: 'Google',
    value: TorusLoginProvider.GOOGLE,
  },
  {
    label: 'Facebook',
    value: TorusLoginProvider.FACEBOOK,
  },
  {
    label: 'Reddit',
    value: TorusLoginProvider.REDDIT,
  },
  {
    label: 'Discord',
    value: TorusLoginProvider.DISCORD,
  },
  {
    label: 'Twitch',
    value: TorusLoginProvider.TWITCH,
  },
  {
    label: 'Apple',
    value: TorusLoginProvider.APPLE,
  },
  {
    label: 'Line',
    value: TorusLoginProvider.LINE,
  },
  {
    label: 'Github',
    value: TorusLoginProvider.GITHUB,
  },
  {
    label: 'Linkedin',
    value: TorusLoginProvider.LINKEDIN,
  },
  {
    label: 'Twitter',
    value: TorusLoginProvider.TWITTER,
  },
];

export default {
  name: 'TorusLogin',
  icon: './icon.png',
  displayName: {
    en: 'TorusLogin',
    ja: 'プラグイン名',
  },
  defaultWidth: 170,
  defaultHeight: 50,
  minWidth: 100,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'text',
      displayName: {
        en: 'Text',
        ja: 'Text',
      },
      type: 'text',
      default: 'Torus login',
    },
    {
      name: 'upperCase',
      displayName: {
        en: 'Uppercase',
        ja: '大文字',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'typeOfLogin',
      displayName: {
        en: 'Type of login',
        ja: 'Type of login',
      },
      type: 'text',
      control: {
        type: 'menu',
        options: optionsTorusLogin,
      },
      default: TorusLoginProvider.GOOGLE,
    },
    {
      name: 'clientId',
      displayName: {
        en: 'Client ID',
        ja: 'Client ID',
      },
      type: 'text',
      default: '',
    },
    {
      name: 'network',
      displayName: {
        en: 'Network',
        ja: 'Network',
      },
      type: 'text',
      control: {
        type: 'menu',
        options: [
          {
            label: 'Mainnet',
            value: 'mainnet',
          },
          {
            label: 'Testnet',
            value: 'testnet',
          },
        ],
      },
      default: 'testnet',
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'Actions',
        ja: 'アクション',
      },
    },
    {
      name: 'backgroundColor',
      type: 'color',
      displayName: {
        en: 'BackgroundColor',
        ja: 'BackgroundColor',
      },
      default: '#34A0DD',
    },
    {
      name: 'color',
      type: 'color',
      displayName: {
        en: 'Text Color',
        ja: 'Text Color',
      },
      default: '#000',
    },
    {
      name: 'borderRadius',
      default: 5,
    },
    {
      name: 'fontFamily',
      default: 'Roboto, sans-serif',
    },
    {
      name: 'fontWeight',
      default: '600',
    },
  ],
  childComponents: [
    {
      name: 'colors',
      displayName: {
        en: 'Background Colors',
        ja: 'Background Colors',
      },
      props: [
        {
          name: 'linerColors',
          displayName: {
            en: 'Liner Colors',
            ja: 'Liner Colors',
          },
          type: 'text',
        },
      ],
    },

    {
      name: 'icons',
      displayName: {
        en: 'More options',
        ja: 'More options',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'false',
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'add',
        },
      ],
    },
    {
      name: 'leadImage',
      displayName: {
        en: 'Lead image',
        ja: 'Lead image',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'false',
        },
        {
          name: 'imageUrl',
          displayName: {
            en: 'Select lead Image URL',
            ja: 'Select lead Image URL',
          },
          type: 'text',
        },
      ],
    },
    {
      name: 'tailImage',
      displayName: {
        en: 'Tail Image',
        ja: 'Tail Image',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'false',
        },
        {
          name: 'imageUrl',
          displayName: {
            en: 'Select tail Image URL',
            ja: 'Select tail Image URL',
          },
          type: 'text',
        },
      ],
    },
  ],
};
