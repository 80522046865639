import { StyleSheet, Platform } from 'react-native';
import { checkStyles } from '../func';
import { isCheckColor } from '../shared';

const createStyles = ({
  attrs,
  width,
  disabled,
  isError,
}: {
  attrs: Record<string, any>;
  width: number;
  disabled: boolean;
  isError: boolean;
}) => {
  const {
    opacity,
    titleCardName,
    titleCardNumber,
    submitButton,
    borderRadius,
    backgroundColor,
  } = attrs;

  return StyleSheet.create({
    container: {
      width,
      opacity,
      backgroundColor: isCheckColor(backgroundColor)
        ? backgroundColor
        : undefined,
    },

    titleCardName: {
      marginBottom: 6,
      ...checkStyles(titleCardName),
    },

    titleCardNumber: {
      marginBottom: 6,
      ...checkStyles(titleCardNumber),
    },

    inputCardName: {
      padding: 10,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: 'rgb(189, 189, 189)',
      marginBottom: 10,
    },

    inputCardContainer: {
      // display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 10,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: 'rgb(189, 189, 189)',
    },

    inputCardImage: {
      // flex: 0.2,
      paddingRight: 8,
    },

    cardImage: { width: 21, height: 14 },

    inputCardNumber: {
      flex: 1,
      paddingRight: 8,
    },

    inputCardExpiration: {
      flex: 0.4,
      paddingRight: 8,
      ...(Platform.OS !== 'android' && { maxWidth: 62 }),
    },

    inputCardCVC: {
      flex: 0.3,
      maxWidth: 40,
    },

    input: {
      fontSize: 14,
      ...(Platform.OS === 'android' && { paddingVertical: 0 }),
      ...(Platform.OS === 'web' && {
        outlineStyle: 'none',
        outlineColor: 'transparent',
        placeholderTextColor: 'rgb(189, 189, 189)',
      }),
    },

    text: {
      paddingTop: 8,
      color: isError ? '#ff0000' : '#008000',
    },

    btnCard: { marginTop: 12 },

    btn: {
      minHeight: 44,
      justifyContent: 'center',
      ...(Platform.OS === 'web' && {
        outlineColor: 'red',
      }),
      opacity: disabled ? 0.6 : 1,
      ...checkStyles({ ...submitButton, borderRadius }),
    },
    removeBtn: {
      minHeight: 44,
      justifyContent: 'center',
      ...(Platform.OS === 'web' && {
        outlineColor: 'red',
      }),
      opacity: 1,
      ...checkStyles({ ...submitButton, borderRadius }),
    },
  });
};

export default createStyles;
