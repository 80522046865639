import { PlanPermission, ListPlan } from 'click-types';

export { ZAPIER_UNLIMITED } from 'click-types';
export type PermissionPlan = PlanPermission;
export type ListPlanItem = ListPlan & {
  currency?: string;
  amountYear: number;
  amountMonth: number;
};

export enum PlanPermissionKey {
  capacity = 'capacity',
  numberOfApps = 'numberOfApps',
  numberOfMembers = 'numberOfMembers',
  numberOfRecords = 'numberOfRecords',
  zapier = 'zapier',
}
export type PlanPermissionItemDetail = {
  current: number;
  max: number;
};
export type PlanPermissionResponse = {
  capacity: PlanPermissionItemDetail;
  numberOfApps: PlanPermissionItemDetail;
  numberOfMembers: PlanPermissionItemDetail;
  numberOfRecords: PlanPermissionItemDetail;
  zapier: PlanPermissionItemDetail;
};

export enum PlanModalType {
  Default = 'Default',
  ReachedAppLimitation = 'ReachedAppLimitation',
  ReachedRecordLimitation = 'ReachedRecordLimitation',
  ReachedCapacityLimitation = 'ReachedCapacityLimitation',
  ReachedAPILimitation = 'ReachedAPILimitation',
  ReachedMembersLimitation = 'ReachedMembersLimitation',
}
