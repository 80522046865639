import { IMap, IObj } from '@common/types';
import { MarkerType } from '@nocode/types/map.type';
import * as _ from 'lodash';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { DEFAULT_CENTER, DEFAULT_DISTANCE } from '../../constans';
import { convertMarker, getDistanceFromCurrent } from '../../functions';
import useLocation from '../useGetPosition';
import axios, { AxiosResponse } from 'axios';

const useMarker = (props: IMap) => {
  const { currentLocation } = useLocation();
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  const {
    dataBinding: markerData,
    attributes,
    data,
    isInCustomList = false,
    record,
    id,
  } = props;

  const [markers, setMarkers] = useState<Array<MarkerType>>([]);

  const selectedAddressMarker = _.get(
    props,
    'attributes.markers.selectedAddressMarker'
  );

  const markerType = _.get(props, 'attributes.markerType', 'simple');

  const markerSource = useMemo(() => {
    return _.get(
      props,
      `attributes.${markerType === 'simple' ? 'marker' : 'markers'}`
    );
  }, [props, markerType]);

  const markerTypeOfPin = _.get(markerSource, 'markerTypeOfPin', 'address');

  const markerProperties = useMemo(() => {
    const markerType = props.attributes?.markerType || 'simple';
    return markerType === 'simple'
      ? props.attributes.marker
      : props.attributes.markers;
  }, [props.attributes]);

  const showCurrentLocation = _.get(markerProperties, 'currentLocation', false);

  const handleConvertData = (data: IObj) => {
    let resp = {};

    if (_.isEmpty(data)) return resp;

    _.forEach(Object.keys(data), (key) => {
      _.set(resp, key, data[key]);
    });

    return resp;
  };

  const simpleData = useMemo(
    () => handleConvertData(!isInCustomList ? data : _.get(record, id, {})),
    [isInCustomList, record, data, id]
  );

  const dataBinding = useMemo(
    () =>
      markerType === 'simple'
        ? [convertMarker(simpleData, attributes)]
        : markerData.map((item: Record<string, any>) =>
            convertMarker(item, attributes)
          ),
    [markerData, data, attributes]
  );

  const requestBody = useMemo(() => {
    const addressMarker = _.chunk(
      _.compact(_.map(dataBinding, 'markerAddress')),
      50
    );
    return _.map(addressMarker, (address) => ({
      apiKey: attributes?.apiKey,
      addresses: address,
    }));
  }, [attributes?.apiKey, target, dataBinding, data]);

  const postAddressMarker = async (requestBody: any) => {
    return await props.mapActions?.postAddressMarker(requestBody);
  };

  const handleSelectedMarker: any = useMemo(() => {
    const firstSelectedMarker: any = _.first(dataBinding);

    return firstSelectedMarker?.selectedAddressMarker;
  }, [dataBinding]);

  const requestSelectedMarker = useMemo(
    () => ({
      apiKey: attributes?.apiKey,
      addresses: [handleSelectedMarker],
    }),
    [attributes?.apiKey, handleSelectedMarker]
  );

  const getGeometryMarker = async () => {
    let data: any[] = [];

    const getGeometryFromAddress = async () => {
      if (
        requestBody &&
        requestBody.length &&
        _.get(requestBody, '[0].addresses')?.length &&
        _.get(requestBody, '[0].apiKey')
      ) {
        // get lat, long of dataBinding
        const requestData: any[] = [];
        for (const requestBD of requestBody) {
          const dataMarker = await postAddressMarker(requestBD);
          requestData.push(...dataMarker);
        }
        const availableData = _.filter(dataBinding, 'markerAddress').map(
          (record, idx) => ({
            ..._.get(requestData.flat(), idx, {}),
            ..._.pick(record, ['id', 'imageMarker', 'record']),
          })
        );
        const listMarkers = _.uniqBy(availableData, 'lat' && 'lng').filter(
          (marker) => !_.isNil(marker.lat) && !_.isNil(marker.lng)
        );
        data.push(...listMarkers);
      }
    };

    if (props.attributes.markerType === 'simple') {
      if (markerTypeOfPin === 'address') {
        await getGeometryFromAddress();
      } else {
        const geometry = dataBinding[0];
        if (geometry) {
          const geometryData = {
            lat: geometry.markerGeometry.lat,
            lng: geometry.markerGeometry.lng,
            imageMarker: geometry.imageMarker,
          };
          geometryData && data.push(geometryData);
        }
      }
    } else {
      if (markerTypeOfPin === 'address') {
        await getGeometryFromAddress();
      } else {
        const records = _.filter(dataBinding, 'markerGeometry').map(
          (record) => ({
            id: record.id,
            ..._.pick(record.markerGeometry, ['lat', 'lng']),
            imageMarker: record.imageMarker,
            record: record?.record,
          })
        );
        const availableData = records
          .filter((marker) => !_.isNil(marker.lat) && !_.isNil(marker.lng))
          .map((marker: any) => ({
            lng: marker.lng,
            lat: marker.lat,
            id: marker?.id,
            imageMarker: marker?.imageMarker,
            record: marker?.record,
          }));
        data.push(...availableData);
      }
      if (requestSelectedMarker && props.attributes.markers?.selectedMarker) {
        const currentPin: any = await postAddressMarker(requestSelectedMarker);
        if (currentPin && !_.isNil(currentPin[0])) {
          currentPin[0].center = true;
          data.push(...currentPin);
        }
      }
    }
    setMarkers(data);
  };

  //center location
  const centerLocation = useMemo(() => {
    const selectedCenter = markers.find((marker) => marker.center);
    if (!isEmpty(selectedCenter)) {
      return selectedCenter;
    } else {
      if (showCurrentLocation) {
        return _.get(
          _.compact([currentLocation, ...markers]),
          '0',
          DEFAULT_CENTER
        );
      } else {
        return _.get(_.compact(markers), '0', DEFAULT_CENTER);
      }
    }
  }, [markers, currentLocation]);

  //selectedMaker
  const selectedMaker = markers.find((marker) => marker.center);

  //imagePlaceholder
  const imagePlaceholder = useMemo(() => {
    const imgSource = _.get(
      markerSource,
      `${markerType === 'simple' ? 'markerImage' : 'listMarkerImage'}`
    );

    return _.get(imgSource, 'binding.options', null);
  }, [markerSource, markerType]);

  //closeMarkers
  const handledDistance = _.get(markers, '[0].distanceSearch');

  const distanceToSearch =
    handledDistance < 0 || !handledDistance
      ? DEFAULT_DISTANCE
      : Number(handledDistance);

  const closeMarkers = useMemo(() => {
    return markers
      .map((marker: any) => {
        return {
          ...marker,
          dis: selectedMaker
            ? getDistanceFromCurrent(selectedMaker, marker)
            : !_.isUndefined(currentLocation) &&
              getDistanceFromCurrent(currentLocation, marker),
        };
      })
      .filter((marker) => marker.dis <= distanceToSearch);
  }, [markers, distanceToSearch]);

  const getMarkers = useCallback(async () => {
    getGeometryMarker();
  }, [
    requestBody,
    dataBinding,
    data,
    markerType,
    selectedAddressMarker,
    markerTypeOfPin,
  ]);

  useLayoutEffect(() => {
    getMarkers();
  }, [dataBinding, data]);

  return {
    markers,
    centerLocation,
    selectedMaker,
    showCurrentLocation,
    imagePlaceholder,
    closeMarkers,
    distanceToSearch,
    handledDistance,
  };
};

export default useMarker;
