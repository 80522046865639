export default {
  name: 'AvatarList',
  displayName: {
    en: 'Avatar List',
    ja: 'アバターリスト',
  },
  icon: './icon.png',
  defaultWidth: 600,
  defaultHeight: 232,
  resizeX: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
    },
    {
      name: 'sortList',
      displayName: {
        en: 'Sorting',
        ja: '並び替え',
      },
      type: 'sort',
    },
    {
      name: 'maximumItems',
      displayName: {
        en: 'Maximum number of items',
        ja: '上限',
      },
      type: 'maximumItems',
    },
    {
      name: 'onPress',
      displayName: {
        en: 'List',
        ja: 'リスト',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
      tabType: 'click-flow',
    },
  ],
  childComponents: [
    {
      name: 'title',
      displayName: {
        en: 'Title',
        ja: 'タイトル',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Title',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'titleLineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.multiLine',
                value: 2,
              },
            ],
          },
        },
        {
          name: 'position',
          displayName: {
            en: 'Text Position',
            ja: 'テキストの位置',
          },
          type: 'number',
          default: 'bottom',
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.styleTab.styleAvatarList.Top',
                value: 'top',
              },
              {
                label: 'canvas.menuRight.styleTab.styleAvatarList.Bottom',
                value: 'bottom',
              },
            ],
          },
        },
        {
          name: 'onPress',
          displayName: {
            en: 'Actions',
            ja: 'アクション',
          },
          type: 'action',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
      ],
    },

    {
      name: 'subTitle',
      displayName: {
        en: 'Sub Title',
        ja: 'サブタイトル',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Sub Title',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'subTitleLineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.multiLine',
                value: 2,
              },
            ],
          },
        },
        {
          name: 'onPress',
          displayName: {
            en: 'Actions',
            ja: 'アクション',
          },
          type: 'action',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
      ],
    },

    {
      displayName: {
        en: 'Image',
        ja: '画像',
      },
      name: 'cardCover',
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'image',
          displayName: {
            en: 'Image Source',
            ja: '画像ソース',
          },
          type: 'image',
        },
        {
          name: 'onPress',
          displayName: {
            en: 'Actions',
            ja: 'アクション',
          },
          type: 'action',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
      ],
    },
  ],
};
