import { ChatComponentType } from '@common/hooks/useChatComponentActions';
import { get, uniqBy } from 'lodash';
import moment from 'moment';
import { getActions, getValueBinding } from '../shared';
import { useChatComponentContext } from './context';
import { ChatComponentBindingValue, ChatComponentProps } from './type';

export const DEFAULT_PAGINATION = { limit: 10, skip: 0, total: 0 };
export const MAX_FILES_COUNT = 5; // maximum upload 5 files in a messages
export const MAX_INPUT_LENGTH = 2000;
export const isPCView = (width: number) => width > 768;
export const PC_WIDTH_GROUP_MESSAGE_VIEW = 325;
export const CHAT_DUMMY_DATA: {
  ChatGroup: ChatComponentType.GroupMessageTypeWithMessageJson[];
  ChatMessages: ChatComponentType.ChatMessagesTypeWithMessageJson[];
  UnReadGroupStatus: Record<string, number>;
} = {
  ChatGroup: [
    {
      id: '1',
      latestMessage: {
        message: {
          text: 'テキストが入りますテキストが入りますテキストが入ります',
        },
        senderAvatar: {
          url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_1.png',
        },
        senderId: '1',
        senderName: 'ユーザー名',
        createdAt: '2025-01-01 12:00:00',
      },
      groupName: 'ユーザー名',
    },
    {
      id: '2',
      latestMessage: {
        message: {
          text: 'テキストが入りますテキストが入りますテキストが入ります',
        },
        senderAvatar: {
          url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_2.jpeg',
        },
        senderId: '2',
        senderName: 'ユーザー名',
        createdAt: '2025-02-04 12:00:00',
      },
      groupName: 'ユーザー名',
    },
    {
      id: '3',
      latestMessage: {
        message: {
          text: 'テキストが入りますテキストが入りますテキストが入ります',
        },
        senderAvatar: {
          url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_3.jpeg',
        },
        senderId: '3',
        senderName: 'ユーザー名',
        createdAt: '2025-02-13 12:00:00',
      },
      groupName: 'ユーザー名',
    },
    {
      id: '4',
      latestMessage: {
        message: {
          text: 'テキストが入りますテキストが入りますテキストが入ります',
        },
        senderAvatar: {
          url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_4.jpeg',
        },
        senderId: '3',
        senderName: 'ユーザー名',
        createdAt: '2025-02-14 12:00:00',
      },
      groupName: 'ユーザー名',
    },
  ],
  UnReadGroupStatus: {
    '1': 1,
    '2': 100,
    '3': 56,
  },
  ChatMessages: [
    {
      id: '1',
      message: {
        text: 'テキストが入りますテキストが入りますテキストが入ります',
        files: [
          {
            url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_1.png',
            type: 'image/png',
            filename: 'example_avatar_1.png',
          },
          {
            url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_2.jpeg',
            filename: 'file1.docx',
            type: 'application/text',
          },
          {
            url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_3.jpeg',
            filename: 'file2_super_long_long_long.docx',
            type: 'application/text',
          },
          {
            url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_4.jpeg',
            type: 'image/jpeg',
            filename: 'example_avatar_4.jpeg',
          },
        ],
      },
      senderAvatar: {
        url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_1.png',
      },
      senderId: '1',
      senderName: 'ユーザー名',
      createdAt: '2025/02/03 12:30:00',
      groupId: '1',
    },
    {
      id: '2',
      message: {
        text: 'テキストが入りますテキストが入りますテキストが入ります',
        files: [
          {
            url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_2.jpeg',
            filename: 'file1.docx',
            type: 'application/text',
          },
          {
            url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_3.jpeg',
            type: 'application/text',
            filename: 'file2_super_long_long_long.docx',
          },
        ],
      },
      senderAvatar: {
        url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_1.png',
      },
      senderId: '1',
      senderName: 'ユーザー名',
      createdAt: '2025/02/03 12:32:00',
      groupId: '1',
    },
    {
      id: '3',
      message: {
        text: 'テキストが入りますテキストが入りますテキストが入ります',
      },
      senderAvatar: {
        url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_4.jpeg',
      },
      senderId: '1',
      senderName: 'ユーザー名',
      createdAt: '2025/02/03 15:00:00',
      groupId: '1',
    },
    {
      id: '4',
      message: {
        text: 'テキストが入りますテキストが入りますテキストが入ります',
      },
      senderAvatar: {
        url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_1.png',
      },
      senderId: '1',
      senderName: 'ユーザー名',
      createdAt: '2025/02/03 15:00:00',
      groupId: '1',
    },
    {
      id: '5',
      message: {
        text: 'テキストが入りますテキストが入りますテキストが入ります',
      },
      senderAvatar: {
        url: 'https://click-assets.s3.ap-northeast-1.amazonaws.com/example_avatar_4.jpeg',
      },
      senderId: '2',
      senderName: 'ユーザー名',
      createdAt: '2025/02/03 13:24:00',
      groupId: '1',
    },
  ],
};
export const FILE_BACKGROUND_URL =
  'https://click-assets.s3.ap-northeast-1.amazonaws.com/file_placeholder.png';

export const useChatComponent = (props: ChatComponentProps) => {
  const {
    chatActions,
    onPress,
    record,
    data,
    isInCustomList,
    id,
    initId,
    changeInput,
  } = props;
  const context = useChatComponentContext();
  const bindingValue = getValueBinding(
    isInCustomList ? initId : id,
    isInCustomList ? record : data,
    props
  ) as ChatComponentBindingValue;
  const sendPushNotification = {
    enabled: get(bindingValue, 'sendNotification.enabled', false),
    title: get(bindingValue, 'sendNotification.title', ''),
  };

  const fetchUnreadStatus = async (groupIds: string[]) => {
    const data = await chatActions.getUnreadStatus(groupIds);
    const unreadMessageStatus = data.reduce<Record<string, number>>(
      (prev, curr) => {
        prev[curr.groupId] = curr.unreadCount;
        return prev;
      },
      {}
    );
    context.setUnreadMessageStatus(unreadMessageStatus);
  };

  const makeReadStatus = async (data: { messageId: string }) => {
    await chatActions.makeReadStatus(data);
  };

  const fetchGroupList = async () => {
    context.setGroupLoading(true);
    const data = await chatActions.getGroupMessages();
    fetchUnreadStatus(data.map((groupMessage) => groupMessage.id));
    context.setGroupMessages(data);
    context.setGroupLoading(false);
    return data;
  };

  const fetchMessageHistory = async ({
    groupId,
    pagination,
  }: {
    groupId: string;
    pagination: { skip: number; limit: number };
  }) => {
    context.setMessageHistoryLoading(true);
    const data = await chatActions.getMessageHistories({ groupId, pagination });
    const oldGroupId = context.selectedGroup?.id;
    if (oldGroupId !== groupId) {
      context.setMessageHistories(
        data.data.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
      );
      context.setMessageHistoriesPagination({
        limit: +data.limit,
        skip: +data.skip,
        total: +data.total,
      });
    } else {
      if (data.data.length) {
        context.setMessageHistories((old) => {
          return uniqBy([...old, ...data.data], (e) => e.id).sort((a, b) =>
            moment(a.createdAt).diff(moment(b.createdAt))
          );
        });
        context.setMessageHistoriesPagination({
          limit: +data.limit,
          skip: +data.skip,
          total: +data.total,
        });
      }
    }
    context.setMessageHistoryLoading(false);
    return data;
  };

  const fetchMessageHistoryNextPage = async () => {
    if (context.selectedGroup) {
      const paginationNext = {
        ...context.messageHistoriesPagination,
        skip:
          context.messageHistoriesPagination.skip +
          context.messageHistoriesPagination.limit,
      };
      fetchMessageHistory({
        groupId: context.selectedGroup?.id,
        pagination: paginationNext,
      });
    }
  };

  const sendMessage = async () => {
    if (
      !context.selectedGroup ||
      (!context.inputValue && !context.filesValue.length)
    ) {
      return;
    }
    context.setMessageSending(true);
    const newMessage = await chatActions.sendMessage({
      groupId: context.selectedGroup?.id,
      message: {
        text: context.inputValue,
        files: context.filesValue,
      },
    });
    changeInput(context.inputValue || '');
    // require setTimeout to update redux (by binding action) before run action
    setTimeout(() => {
      onPress?.(getActions(props, 'action'), {
        record: { messageId: newMessage.id, sendPushNotification },
      });
    }, 1000);
    context.setInputValue('');
    context.setFilesValue([]);
    context.setMessageHistories((old) => [...old, newMessage]);
    context.setMessageSending(false);
    if (context.messageHistories.length) {
      setTimeout(() => {
        // scroll to end
        context.listRef?.current?.scrollToIndex({
          index: context.messageHistories.length - 1,
          animated: true,
        });
      }, 300);
    }
    fetchGroupList();
  };

  const onSelectGroup = async (groupId: string | null) => {
    if (!groupId) {
      context.setSelectedGroup(null);
      return;
    }
    context.setSelectedGroup(groupId);
    context.setMessageHistories([]);
    const data = await fetchMessageHistory({
      groupId,
      pagination: context.messageHistoriesPagination,
    });
    if (data.data.length) {
      setTimeout(() => {
        // scroll to end
        try {
          context.listRef?.current?.scrollToIndex({
            index: data.data.length - 1,
            animated: true,
          });
        } catch (e) {
          // nothing
        }
      }, 300);
    }
  };

  return {
    fetchGroupList,
    fetchMessageHistory,
    makeReadStatus,
    sendMessage,
    onSelectGroup,
    fetchMessageHistoryNextPage,
  };
};

export type UseChatComponentProps = ReturnType<typeof useChatComponent>;
