export default {
  name: 'Icon',
  displayName: {
    en: 'Icon',
    ja: 'アイコン',
  },
  icon: 'icon.png',
  defaultWidth: 24,
  defaultHeight: 24,
  props: [
    {
      name: 'iconName',
      displayName: {
        en: 'Icon',
        ja: 'アイコン',
      },
      type: 'icon',
      default: 'directions-bike',
    },
    {
      name: 'iconColor',
      displayName: {
        en: 'Color',
        ja: '色',
      },
      type: 'color',
      default: '#666666',
    },
    // {
    //   name: 'iconSize',
    //   displayName: 'Size',
    //   type: 'number',
    //   default: 24,
    //   control: {
    //     type: 'slider',
    //     max: 70,
    //     min: 1,
    //   },
    // },
    {
      name: 'onPress',
      type: 'action',
      displayName: {
        en: 'Icon',
        ja: 'Icon',
      },
    },
  ],
  resizeX: false,
  resizeY: false,
};
