import cardValidator from 'card-validator';
import { get } from 'lodash';
import { useEffect, useRef, useState, useMemo } from 'react';
import { Linking, Platform } from 'react-native';
import moment from 'moment';

import {
  FincodeActionsType,
  FincodeAppOrderEntity,
  FincodeCard,
  FincodeConfig,
} from '@common/hooks/useFincode';
import { getMessage } from '../../utils/locale';
import { getActions, getValueBinding, sleep } from '../shared';
import {
  URI_IMAGE_CARD,
  checkTypeCardPayment,
} from './../../utils/cardPayment';
import {
  CARD,
  amountFormatter,
  cardExpirationDateFormatter,
  cardNumberFormatter,
  getTextErrors,
  toNonAccentVietnamese,
} from './constant';
import BaseComponent from '@nocode/types/base.type';

const ActionStatus = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  ERROR: 'ERROR',
};
const FINCODE_ORDER_ID_NAME = 'fincodeOrderId'; // get from config file
export type CardInfo = {
  brand: string;
  cardName: string;
  cardNumber: string;
  cardExpiration: string;
  cardCvc: string;
  cardId?: string;
  text: {
    status: 'successful' | 'failed' | 'notValidOrRequired' | null;
    message: string | null;
  };
};

export type ReceivedProps = BaseComponent & {
  attributes: Record<string, any>;
  groupActionId: any;
  onPress: (id: string | undefined, { groupActionId }?: any) => Promise<any>;
  locale: string;
  id: string;
  appid: string;
  isConnected: boolean;
  testModeShopId: string;
  data: Record<string, any>;
} & FincodeActionsType & {
    auth?: {
      email: string;
    };
  };

export type FincodeBindingValue = {
  'paymentOptions.paymentAmount': number;
  'paymentOptions.typeCurrency': 'jpy';
  'titleCardName.enabled': boolean;
  'titleCardName.text': string;
  'titleCardNumber.enabled': boolean;
  'titleCardNumber.text': string;
  submitType: 'payment' | 'saveCard' | 'subscription';
  'submitButton.text': string;
  'rememberCheckbox.removeCardText': string;
  'rememberCheckbox.enabled': boolean;
  'rememberCheckbox.icon': string;
  'rememberCheckbox.text': string;
  'darkMode.enabled': boolean;
  'paymentResponse.payment': string;
  'paymentResponse.saveCard': string;
  'subscriptionConfig.planId': string;
  'subscriptionConfig.startDate': string;
  'subscriptionConfig.stopDate': string;
  'subscriptionConfig.billingTiming': 'designatedDate' | 'lastDayOfTheMonth';
  'subscriptionConfig.initAmount': number;
  'subscriptionConfig.useInitAmount': boolean;
  testModeEnabled: boolean;
};

const initialState = {
  brand: '',
  cardName: '',
  cardNumber: '',
  cardExpiration: '',
  cardCvc: '',
  text: { status: null, message: null },
};

type CHECK_CARD = {
  niceType: string;
  type: URI_IMAGE_CARD;
  patterns: number[] | null;
  gaps: number[] | null;
  lengths: number[];
  code: {
    name: string;
    size: number;
  };
  matchStrength?: number | null;
};

const useFincode = (props: ReceivedProps) => {
  const {
    attributes,
    locale,
    id,
    data,
    appid,
    isConnected,
    testModeShopId,
    auth,
    changeInput,
    onPress,
    getConfig,
    executePayment,
    createSubscription,
    saveCard,
    getCardDefault,
    removeCardDefault,
    getPaymentStatus,
  } = props;
  const emailBuyer = auth?.email || '';
  const bindingValue = getValueBinding(id, data, props) as FincodeBindingValue;
  const typeCurrency = bindingValue['paymentOptions.typeCurrency'];
  const paymentAmount = bindingValue['paymentOptions.paymentAmount'];
  const submitButtonType = bindingValue['submitType'];
  const rememberCardEnabled = get(
    bindingValue,
    'rememberCheckbox.enabled',
    false
  );
  const paymentSuccessText = get(
    bindingValue,
    'paymentResponse.payment',
    'Payment successful!'
  );
  const saveCardSuccessText = get(
    bindingValue,
    'paymentResponse.saveCard',
    'Save card successful!'
  );
  const testModeEnabled = get(bindingValue, 'testModeEnabled', false);
  const testMode = testModeEnabled ? { shopId: testModeShopId } : undefined;

  const textErrors = getTextErrors();

  const [loading, setLoading] = useState(false);
  const [fincodeConifgLoading, setFincodeConfigLoading] = useState(false);
  const [card, setCard] = useState<CardInfo>(initialState);
  const [rememberCard, setRememberCard] = useState(false);
  const [fincodeConfig, setFincodeConfig] = useState<FincodeConfig>();

  const cardNameRef = useRef<any>(null);
  const cardNumberRef = useRef<any>(null);

  const { cardName, cardNumber, cardExpiration, cardCvc, text, cardId } = card;

  const isPaymentAction =
    submitButtonType === 'payment' || submitButtonType === 'subscription';
  const getCardSaved =
    rememberCardEnabled ||
    submitButtonType === 'subscription' ||
    submitButtonType === 'saveCard';
  const isShowCardSaved = getCardSaved && !!cardId;

  const checkTypeCard: CHECK_CARD = useMemo(() => {
    return checkTypeCardPayment(cardNumber);
  }, [cardNumber]);

  const uriImage = !cardNumber
    ? URI_IMAGE_CARD['default']
    : cardNumber
    ? URI_IMAGE_CARD[checkTypeCard.type]
    : URI_IMAGE_CARD['invalid'];

  const isPassCardName = new RegExp(/^[A-Za-z].*.*[A-Za-z]$/g).test(cardName);

  const isPassCardNumber = checkTypeCard.lengths.includes(
    cardNumber.replace(/\s/g, '').length
  );

  const isPassCardExpiration =
    cardValidator.expirationDate(cardExpiration).isValid;

  const isPassCardCvc = cardCvc.length === checkTypeCard.code.size;

  const isPassEmail =
    emailBuyer &&
    emailBuyer
      .toString()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const isCanvas =
    window?.location?.href &&
    (window.location.href.split('/').includes('canvas') ||
      window.location.href.split('/').includes('view'));

  const isDisableButton = loading
    ? true
    : cardId && isPaymentAction
    ? false
    : (text.message && text.status === 'notValidOrRequired') ||
      !isPassCardName ||
      checkTypeCard.type === 'invalid' ||
      !isPassCardNumber ||
      !isPassCardExpiration ||
      !isPassCardCvc ||
      !isPassEmail;

  const amountPayload = amountFormatter(paymentAmount, typeCurrency);

  useEffect(() => {
    setCard(initialState);
  }, [id]);

  useEffect(() => {
    if (isCanvas) {
      return;
    }
    if (isShowCardSaved) {
      return;
    }
    if (!paymentAmount) {
      return;
    }
    const checkStatusCard =
      !isConnected ||
      !isPassEmail ||
      amountPayload === 0 ||
      !isPassCardName ||
      !isPassCardNumber ||
      !isPassCardExpiration ||
      !isPassCardCvc
        ? 'notValidOrRequired'
        : null;
    const checkMessageCard = !isConnected
      ? textErrors.connectionRequired
      : !isPassEmail
      ? textErrors.emailInvalid
      : amountPayload === 0
      ? getMessage({ id: 'INVALID_AMOUNT', locale })
      : cardName && !isPassCardName
      ? textErrors.cardNameInvalid
      : cardNumber && !isPassCardNumber
      ? textErrors.cardNumberInvalid
      : cardExpiration && !isPassCardExpiration
      ? textErrors.cardExpirationInvalid
      : cardCvc && !isPassCardCvc
      ? textErrors.cardCvcInvalid
      : null;

    setCard((prev) => ({
      ...prev,
      text: {
        status: checkStatusCard,
        message: checkMessageCard,
      },
    }));
  }, [
    isConnected,
    emailBuyer,
    amountPayload,
    cardName,
    cardNumber,
    cardExpiration,
    cardCvc,
    isShowCardSaved,
    paymentAmount,
  ]);

  // validate card number
  useEffect(() => {
    if (!emailBuyer) {
      return;
    }
    if (Platform.OS === 'web') {
      const cardNumberInput = cardNumberRef?.current;

      const listener = (e: any) => {
        const field = e.target;
        let position = field.selectionEnd;
        const length = field.value.length;

        handleChange(field.value, CARD.CARD_NUMBER);

        field.selectionEnd =
          position +
          (field.value.charAt(position - 1) === ' ' &&
          field.value.charAt(length - 1) === ' '
            ? 1
            : 0);
      };
      cardNumberInput?.addEventListener('input', listener);
      return () => {
        cardNumberInput?.removeEventListener('input', listener);
      };
    }
  }, [cardNameRef, cardNumberRef, checkTypeCard]);

  // get card default
  useEffect(() => {
    if (!isCanvas && emailBuyer) {
      if (getCardSaved) {
        setLoading(true);
        getCardDefault({ appId: appid, testMode })
          .then((card: FincodeCard) => {
            if (card) {
              setCardFromFincodeCard(card);
            }
          })
          .catch((error: any) => {
            if (error?.code !== 404) {
              console.error('===error get card', error);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [id, emailBuyer, submitButtonType]);

  // init fincode
  useEffect(() => {
    if (!isCanvas && emailBuyer) {
      setFincodeConfigLoading(true);
      getConfig(testModeEnabled).then((config) => {
        setFincodeConfig(config);
        setFincodeConfigLoading(false);
      });
    }
  }, [emailBuyer, testModeEnabled]);

  const setCardFromFincodeCard = (card: FincodeCard) => {
    const cardExpMonth = card.expire.substring(0, 2);
    const cardExpYear = card.expire.substring(2);
    setCard({
      cardCvc: '000', // default
      brand: card.brand?.toLowerCase(),
      cardExpiration: `${cardExpMonth}/${cardExpYear}`,
      cardName: card.holder_name,
      cardNumber: card.card_no.substring(card.card_no.length - 4),
      cardId: card.id,
      text: {
        message: null,
        status: null,
      },
    });
  };

  const handleCheckRequired = () => {
    if (!text.status) {
      const keys: Record<string, string> = {
        cardName,
        cardNumber,
        cardExpiration,
        cardCvc,
        emailBuyer,
      };

      const cardRequired = Object.keys(keys).find((key) => !keys[key]);
      if (!cardRequired) {
        setCard((prev) => ({ ...prev, text: { status: null, message: null } }));
        return;
      }

      const checkStatusCardRequired = [
        CARD.CARD_NAME,
        CARD.CARD_NUMBER,
        CARD.CARD_EXPIRATION,
        CARD.CARD_CVC,
      ].includes(cardRequired)
        ? 'notValidOrRequired'
        : null;
      const checkMessageCardRequired =
        cardRequired === CARD.CARD_NAME
          ? textErrors.cardNameRequired
          : cardRequired === CARD.CARD_NUMBER
          ? textErrors.cardNumberRequired
          : cardRequired === CARD.CARD_EXPIRATION
          ? textErrors.cardExpirationRequired
          : cardRequired === CARD.CARD_CVC
          ? textErrors.cardCvcRequired
          : cardRequired === 'emailBuyer'
          ? textErrors.emailRequired
          : null;

      setCard((prev) => ({
        ...prev,
        text: {
          status: checkStatusCardRequired,
          message: checkMessageCardRequired,
        },
      }));
    } else {
      setCard((prev) => ({ ...prev, text: { status: null, message: null } }));
    }
  };

  const handleChange = (value: string, name: string) => {
    setCard((prev) => {
      switch (name) {
        case CARD.CARD_NAME:
          return {
            ...prev,
            [name]: value,
          };
        case CARD.CARD_NUMBER:
          return {
            ...prev,
            [name]: cardNumberFormatter(
              prev.cardNumber,
              value,
              checkTypeCard.gaps
            ),
            [CARD.CARD_NAME]: toNonAccentVietnamese(
              prev.cardName.toUpperCase()
            ),
          };
        case CARD.CARD_EXPIRATION:
          return {
            ...prev,
            [name]: cardExpirationDateFormatter(prev.cardExpiration, value),
          };
        default:
          return { ...prev, [name]: value };
      }
    });
  };

  const handleTextSubmit = () => {
    setCard((prev) => {
      return {
        ...prev,
        cardName: toNonAccentVietnamese(prev.cardName.toUpperCase()),
      };
    });
  };

  const saveUserCard = async () => {
    const cardToken = await getCardToken();
    if (cardToken) {
      const card = await saveCard(
        {
          cardToken,
          appId: appid,
        },
        testMode
      );
      setCardFromFincodeCard(card);
      setCard((prev) => ({
        ...prev,
        text: { status: 'successful', message: saveCardSuccessText },
      }));
      return card;
    }
  };

  const _handlePayment = async () => {
    if (cardId) {
      return await executePayment(
        {
          amount: amountPayload,
          appId: appid,
          cardId,
          payType: 'Card',
        },
        testMode
      );
    }
    const cardToken = await getCardToken();
    if (cardToken) {
      return await executePayment(
        {
          amount: amountPayload,
          appId: appid,
          cardToken,
          payType: 'Card',
        },
        testMode
      );
    }
  };

  const _handleSubscription = async () => {
    const fincodePlanId = get(bindingValue, 'subscriptionConfig.planId');
    const startDate =
      get(bindingValue, 'subscriptionConfig.startDate') ||
      new Date().toISOString();
    const stopDate = get(bindingValue, 'subscriptionConfig.stopDate');
    const endMonthFlag =
      get(bindingValue, 'subscriptionConfig.billingTiming') ===
      'lastDayOfTheMonth';
    const initAmount = get(bindingValue, 'subscriptionConfig.initAmount', 0);
    const useInitAmount = get(
      bindingValue,
      'subscriptionConfig.useInitAmount',
      false
    );
    let cardId = card.cardId;
    if (!cardId) {
      const card = await saveUserCard();
      cardId = card?.id;
    }
    if (cardId) {
      return await createSubscription(
        {
          fincodePlanId,
          endMonthFlag,
          startDate: new Date(startDate),
          stopDate: moment(stopDate).isValid() ? new Date(stopDate) : undefined,
          initAmount: useInitAmount ? +initAmount || 0 : 0,
          cardId,
        },
        appid,
        testMode
      );
    }
  };

  const waitPaymentSuccess = async (orderId: string) => {
    const timeout = 15000; // 15 seconds
    let payment: FincodeAppOrderEntity;
    return new Promise(async (resolve, reject) => {
      do {
        payment = await getPaymentStatus(orderId);
        if (payment.paymentStatus === 'CAPTURED') {
          return resolve(true);
        }
        if (payment.paymentStatus === 'CANCELED') {
          return reject(payment.cancelReason || 'Unknown error');
        }
        await sleep(3000);
      } while (true);
    });
  };

  const openWebview = async (url: string) => {
    if (Platform.OS === 'web') {
      window.open(url);
      return;
    }
    try {
      await Linking.openURL(url);
    } catch (err) {
      alert('Please try again!');
    }
  };

  const handleSubmit = async () => {
    handleCheckRequired();
    if (text.status && text.status !== 'successful') {
      return;
    }
    try {
      setLoading(true);
      let beforeActionsResponse = [];
      if (attributes?.beforeActions) {
        let payload = {};
        if (isPaymentAction) {
          let cardToken;
          if (!cardId) {
            cardToken = await getCardToken();
          }
          payload = {
            emailBuyer,
            cardToken,
            cardId,
          };
        } else {
          payload = {
            emailBuyer,
            appid,
          };
        }
        beforeActionsResponse = await handleAction('beforeActions', {
          record: payload,
        });
      }

      let clickPayOrderId = '';
      const purchaseResponse = beforeActionsResponse?.find(
        ({ actionType }: { actionType: string }) =>
          actionType === 'fincode_purchase_product_all'
      ) as { status: keyof typeof ActionStatus; message?: string };
      if (purchaseResponse) {
        if (purchaseResponse.status === ActionStatus.FAILED) {
          throw purchaseResponse;
        }
      } else {
        if (submitButtonType === 'payment') {
          const paymentResponse = await _handlePayment();
          if (paymentResponse?.acs_url) {
            const url = paymentResponse.acs_url;
            openWebview(url);
            await waitPaymentSuccess(paymentResponse.id);
          }
          if (paymentResponse?.id) {
            clickPayOrderId = paymentResponse?.id;
          }
        }
        if (submitButtonType === 'subscription') {
          const paymentResponse = await _handleSubscription();
          if (paymentResponse?.subscriptionId) {
            clickPayOrderId = paymentResponse?.subscriptionId;
          }
        }
      }
      setCard((prev) => ({
        ...prev,
        text: { status: 'successful', message: paymentSuccessText },
      }));
      if (submitButtonType === 'saveCard' || rememberCard) {
        // only use card token for executePayment or save card
        await saveUserCard();
      }
      await changeInput({
        [FINCODE_ORDER_ID_NAME]: clickPayOrderId,
      });
      if (attributes?.successActions) {
        const _successActionsResponse = await handleAction('successActions', {
          ...attributes?.successActions,
          actionData: {
            [FINCODE_ORDER_ID_NAME]: clickPayOrderId,
          },
        });
      }
    } catch (error: any) {
      let errorMessage = get(error, 'message') || 'Error';
      if (Array.isArray(error.errors) && error.errors.length) {
        errorMessage = error.errors
          .map((e: { error_message: string }) => e.error_message)
          .join(', ');
      }
      setCard((prev) => ({
        ...prev,
        text: {
          status: 'failed',
          message: errorMessage,
        },
      }));

      if (attributes?.failedActions) {
        const _failureActionsResponse = await handleAction(
          'failedActions',
          attributes?.failedActions
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAction = async (id: string, data: any = {}) => {
    const arrayAction = getActions(props, id);
    return await onPress(arrayAction, data);
  };

  const getCardToken = async (): Promise<string | null> => {
    if (!fincodeConfig) {
      return null;
    }
    const { baseUrl, publicKey } = fincodeConfig;
    const getCardTokenUrl = `${baseUrl}/v1/tokens/cards`;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Bearer ${publicKey}`);

    const cardExpirationMonth = card.cardExpiration.split('/')[0];
    const cardExpirationYear = card.cardExpiration.split('/')[1];
    const res = await fetch(getCardTokenUrl, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        card_no: card.cardNumber.replace(/\s/g, ''),
        expire: `${cardExpirationYear}${cardExpirationMonth}`,
        holder_name: card.cardName,
        security_code: card.cardCvc,
        number: 1,
      }),
    }).then(async (response) => {
      const responseJson = await response.json();
      const success = response.ok;
      if (success) {
        return responseJson;
      }
      throw responseJson;
    });
    if (res.list?.length) {
      return res.list[0].token;
    }
    return null;
  };

  const handleRemoveCard = async () => {
    setLoading(true);
    try {
      await removeCardDefault({ appId: appid, testMode });
      setCard(initialState);
    } catch (error: any) {
      setCard((prev) => ({
        ...prev,
        text: {
          status: 'failed',
          message: error?.message,
        },
      }));
    } finally {
      setLoading(false);
    }
  };

  const onClickCheckbox = () => {
    setRememberCard((prev) => !prev);
  };

  return {
    ...props,
    loading: loading || fincodeConifgLoading,
    card,
    uriImage,
    isDisableButton,
    attributes,
    bindingValue,
    checkTypeCard,
    handleChange,
    handleTextSubmit,
    handleSubmit,
    handleRemoveCard,
    cardNameRef,
    cardNumberRef,
    onClickCheckbox,
    rememberCard,
    isShowCardSaved,
    isPaymentAction,
  };
};

export type Props = ReturnType<typeof useFincode>;

export default useFincode;
