import React from 'react';
import Svg, { Path } from 'react-native-svg';

const ChevronLeftIcon = () => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z"
        fill="#6C777C"
      />
    </Svg>
  );
};

export default ChevronLeftIcon;
