import moment from 'moment';
import React from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { getDateTimeRelative } from '../../../utils/datetime';
import { ChatComponentType } from '@common/hooks/useChatComponentActions';
import { SpHeader } from './SpHeader';
import { ONE_DAY } from '../../shared';

const GroupItem = ({
  data,
  groupName,
  unreadCount,
  onPress,
}: {
  data: ChatComponentType.GroupMessageTypeWithMessageJson['latestMessage'];
  unreadCount?: number;
  onPress: VoidFunction;
  groupName: string;
}) => {
  const { createdAt, senderAvatar, message } = data || {};
  const unreadCountStr = unreadCount
    ? Number(unreadCount) > 99
      ? '99+'
      : `${unreadCount}`
    : null;
  return (
    <TouchableOpacity style={styles.card} onPress={onPress}>
      <Image style={styles.avatar} source={{ uri: senderAvatar?.url }} />
      <View style={styles.content}>
        <View style={styles.commentHeader}>
          <Text style={styles.username}>{groupName}</Text>
          <Text style={styles.time}>
            {createdAt
              ? getDateTimeRelative(
                  {
                    offset:
                      moment(createdAt).diff(moment(), 'milliseconds') /
                      ONE_DAY,
                  },
                  'ja'
                )
              : ''}
          </Text>
        </View>
        <View style={styles.messageContainer}>
          <Text style={styles.message} numberOfLines={1}>
            {message?.text || ''}
          </Text>
          {unreadCountStr ? (
            <View style={styles.unReadContainer}>
              <Text style={styles.unReadText}>{unreadCountStr}</Text>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const GroupMessage = ({
  messages,
  onSelectGroup,
  unreadMessageStatus,
}: {
  messages: ChatComponentType.GroupMessageTypeWithMessageJson[];
  unreadMessageStatus: Record<string, number>;
  onSelectGroup?: (groupId: string | null) => void;
}) => {
  return (
    <View
      style={{
        marginHorizontal: 4,
        borderRadius: 4,
      }}
      nativeID="GroupMessage"
    >
      <SpHeader text={'チャット一覧'} />
      <ScrollView>
        {messages.map((message, index) => (
          <GroupItem
            key={index}
            groupName={message.groupName || ''}
            data={message.latestMessage}
            onPress={() => {
              onSelectGroup?.(message.id);
            }}
            unreadCount={unreadMessageStatus[message.id]}
          />
        ))}
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  card: {
    flexDirection: 'row',
    padding: 16,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: '#E9EFF2',
    alignItems: 'center',
  },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#F2F5F7',
  },
  content: {
    flex: 1,
    marginLeft: 16,
  },
  commentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  username: {
    fontSize: 13,
    fontWeight: '700',
    color: '#1A2737',
  },
  time: {
    fontSize: 11,
    fontWeight: '400',
    color: '#ADB6B9',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  message: {
    flex: 1,

    fontSize: 12,
    fontWeight: '400',
    color: '#6C777C',
    marginTop: 4,
    overflow: 'hidden',
  },
  unReadContainer: {
    marginHorizontal: 4,
    paddingHorizontal: 6,
    minWidth: 22,
    backgroundColor: '#0175FF',
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  unReadText: {
    color: '#fff',
  },
});
