import React from 'react';
import { View } from 'react-native';
import { GroupMessage } from '../components/GroupList';
import { MessageHistory } from '../components/MessageHistory';
import {
  CHAT_DUMMY_DATA,
  isPCView,
  PC_WIDTH_GROUP_MESSAGE_VIEW,
} from '../hook';
import { chatComponentStyles } from '../style';
import { ChatComponentProps } from '../type';

const CanvasViewContent = (props: ChatComponentProps) => {
  const styled = chatComponentStyles(props);
  if (isPCView(props.width)) {
    return (
      <View style={styled.pcContainer}>
        <View
          style={{
            width: PC_WIDTH_GROUP_MESSAGE_VIEW,
          }}
        >
          <GroupMessage
            messages={CHAT_DUMMY_DATA.ChatGroup}
            unreadMessageStatus={CHAT_DUMMY_DATA.UnReadGroupStatus}
          />
        </View>
        <View
          style={{
            width: props.width - PC_WIDTH_GROUP_MESSAGE_VIEW - 8,
          }}
        >
          <MessageHistory
            messages={CHAT_DUMMY_DATA.ChatMessages}
            groupName="ユーザー名"
            context={{} as any}
            hookActions={{} as any}
          />
        </View>
      </View>
    );
  }
  return (
    <GroupMessage
      messages={CHAT_DUMMY_DATA.ChatGroup}
      unreadMessageStatus={CHAT_DUMMY_DATA.UnReadGroupStatus}
    />
  );
};
export const CanvasView = (props: ChatComponentProps) => {
  const styled = chatComponentStyles(props);
  return (
    <View style={styled.container} nativeID="CanvasView">
      <CanvasViewContent {...props} />
    </View>
  );
};
