import { ActionStatus } from '@common/constants/shared';
import { focusInput } from '@common/redux/slice/formInputs';
import store from '@common/redux/store';
import { get } from 'lodash';
import { ActionResponse } from '../excuteAction';

export const focusOnInput: (action: any) => Promise<ActionResponse> = async (
  action: any
) => {
  const dispatch = store.dispatch;
  dispatch(focusInput(get(action, 'options')));
  return {
    status: ActionStatus.SUCCESS,
  };
};
