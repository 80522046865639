export default {
  name: 'StripePaymentSecurity',
  displayName: {
    en: 'Stripe Security',
    ja: 'Stripe接続',
  },
  icon: './icon.png',
  defaultWidth: 330,
  defaultHeight: 130,
  resizeX: true,
  resizeY: true,

  props: [
    {
      name: 'Settings',
      displayName: {
        en: 'Settings',
        ja: 'Settings',
      },
      type: 'payment',
    },
    {
      name: 'beforeActions',
      displayName: {
        en: 'Actions before Payment',
        ja: '支払い前のアクション',
      },
      type: 'action',
    },
    {
      name: 'successActions',
      displayName: {
        en: 'Actions when Stripe Payment is paid successful',
        ja: '決済成功時のアクション',
      },
      type: 'action',
    },
    {
      name: 'failedActions',
      displayName: {
        en: 'Actions when Stripe Payment is paid failed',
        ja: '決済失敗時のアクション',
      },
      type: 'action',
    },
    {
      name: 'activeColor',
      displayName: {
        en: 'Active Color',
        ja: 'アクティブ時の色',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'inactiveColor',
      displayName: {
        en: 'Inactive Color',
        ja: '非アクティブ時の色',
      },
      type: 'color',
      default: '#bbb',
    },
    {
      name: 'color',
      displayName: {
        en: 'Color',
        ja: '色',
      },
      type: 'color',
      default: '#fff',
    },
  ],
  childComponents: [
    {
      name: 'email',
      displayName: {
        en: 'Email Field',
        ja: 'メールアドレス',
      },
      props: [
        {
          name: 'emailBuyer',
          displayName: {
            en: 'Buyer email',
            ja: '購入者のメールアドレス',
          },
          type: 'text',
          default: '',
        },
      ],
    },

    {
      name: 'paymentOptions',
      displayName: {
        en: 'Payment Options',
        ja: '支払い金額',
      },
      props: [
        {
          name: 'paymentAmount',
          displayName: {
            en: 'Payment Amount',
            ja: '支払い金額',
          },
          type: 'number',
          default: 0,
        },

        {
          name: 'typeCurrency',
          displayName: {
            en: 'Currency Type',
            ja: '通貨',
          },
          type: 'text',
          default: 'jpy',
          control: {
            type: 'menu',
            options: [
              {
                label: 'JPY',
                value: 'jpy',
              },
              {
                label: 'USD',
                value: 'usd',
              },
            ],
          },
        },
      ],
    },

    {
      name: 'changedescription',
      displayName: {
        en: 'Payment Details',
        ja: '支払い詳細',
      },
      props: [
        {
          name: 'description',
          displayName: {
            en: 'Description',
            ja: '詳細',
          },
          type: 'text',
          default: '',
        },
      ],
    },

    {
      name: 'submitButton',
      displayName: {
        en: 'Submit Button',
        ja: '送信ボタン',
      },
      props: [
        {
          name: 'type',
          displayName: {
            en: 'Type',
            ja: 'タイプ',
          },
          type: 'text',
          default: 'payment',
          control: {
            type: 'menu',
            options: [
              {
                label: 'Payment',
                value: 'payment',
              },
              {
                label: 'Save card',
                value: 'saveCard',
              },
            ],
          },
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Pay Now',
        },
        {
          name: 'borderRadius',
          displayName: 'Rounding',
          type: 'number',
          control: {
            type: 'slider',
            max: 100,
            min: 0,
          },
          default: 100,
          enabled: {
            type: ['contained', 'outlined'],
          },
        },
      ],
    },

    {
      name: 'testMode',
      displayName: {
        en: 'Test Mode',
        ja: 'テストモード',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'false',
        },
        {
          name: 'publishableKey',
          displayName: {
            en: 'Publishable Key',
            ja: '公開可能キー',
          },
          type: 'text',
          default: '',
          placeholder: 'pk_test_xxxxxxxxxx',
        },
        {
          name: 'secretKey',
          displayName: {
            en: 'Secret Key',
            ja: 'シークレットキー',
          },
          type: 'text',
          default: '',
          placeholder: 'sk_test_xxxxxxxxxx',
        },
      ],
    },
    {
      name: 'title',
      displayName: {
        en: 'Title',
        ja: 'タイトル',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'text',
          displayName: {
            en: 'Title',
            ja: 'Title',
          },
          type: 'text',
          default: '',
          placeholder: 'Card title',
        },
      ],
    },
    {
      name: 'rememberCheckbox',
      displayName: {
        en: 'Remember Checkbox',
        ja: 'チェックボックスを忘れないでください',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Remember Card',
        },
        {
          name: 'cardTokenSaved',
          placeholder: 'card_xxxxxxxxxxxxxxxxxxxxxxxxxx',
          displayName: {
            en: 'Card saved',
            ja: 'Card saved',
          },
          control: {
            type: 'field',
            dataTypes: ['string'],
            auth: true,
          },
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'checkbox-blank-circle',
        },
      ],
    },
  ],
};
