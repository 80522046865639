import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

type Obj = { [key: string]: any };

type State = {
  values: Obj;
  defaultValues: Obj;
  error: Record<string, any>;
  formValue: Record<string, any>;
  inputIdFocus?: string;
};

const initialState: State = {
  values: {},
  defaultValues: {},
  error: {
    message: '',
    isError: false,
  },
  formValue: {},
  inputIdFocus: undefined,
};

const fromInputSlice = createSlice({
  name: 'formInput',
  initialState: initialState,
  reducers: {
    setDefaultValueInput: (state: State, action: PayloadAction<Obj>) => {
      return {
        ...state,
        defaultValues: {
          ...state.defaultValues,
          ...action.payload,
        },
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    },
    resetValue: (state: State, action: PayloadAction<Obj>) => {
      let newState = { ...state, values: { ...state.values } };
      let arrId = Object.keys(action.payload);
      for (let i = 0; i < arrId.length; i++) {
        const key = arrId[i];
        if (!isEmpty(newState.defaultValues[key])) {
          newState.values[key] = newState.defaultValues[key];
        } else {
          delete newState.values[key];
        }
      }
      return newState;
    },
    resetAllValue: (state: State) => {
      state.defaultValues = {};
      state.values = {};
    },
    setValueInput: (state: State, action: PayloadAction<any>) => {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    },
    setError: (state: State, action: PayloadAction<any>) => {
      state.error = {
        message: action.payload.message,
        isError: true,
      };
    },
    resetError: (state: State) => {
      state.error = initialState.error;
    },
    setFormValue: (state: State, action: PayloadAction<any>) => {
      return {
        ...state,
        formValue: {
          ...state.formValue,
          ...action.payload,
        },
      };
    },
    resetValueForm: (state: State, action: PayloadAction<Obj>) => {
      let newState = { ...state, formValue: { ...state.formValue } };
      let arrId = Object.keys(action.payload);
      for (let i = 0; i < arrId.length; i++) {
        delete newState.formValue[arrId[i]];
      }
      return newState;
    },
    focusInput: (state: State, action: PayloadAction<{ inputId: string }>) => {
      const { inputId } = action.payload;
      state.inputIdFocus = inputId;
      return state;
    },
  },
});
export const {
  setDefaultValueInput,
  resetValue,
  setValueInput,
  resetAllValue,
  setError,
  resetError,
  setFormValue,
  resetValueForm,
  focusInput,
} = fromInputSlice.actions;

export default fromInputSlice.reducer;
