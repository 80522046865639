import React from 'react';
import {
  Image,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import CloseCircleIcon from '@common/components/Icons/CloseCircleIcon';
import DownloadIcon from '@common/components/Icons/DownloadIcon';

interface ImagePreviewModalProps {
  imageUrl: string;
  visible: boolean;
  onClose: () => void;
  onDownload: () => void;
}

const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({
  imageUrl,
  visible,
  onClose,
  onDownload,
}) => {
  const { width, height } = useWindowDimensions();

  return (
    <Modal transparent visible={visible} animationType="fade">
      <View style={styles.overlay}>
        {/* Close Button (Top Right) */}
        <TouchableOpacity style={styles.closeButton} onPress={onClose}>
          <CloseCircleIcon color="#dedede" />
        </TouchableOpacity>

        {/* Download Button (Top Center) */}
        <TouchableOpacity style={styles.downloadContainer} onPress={onDownload}>
          <DownloadIcon />
          <Text style={styles.downloadText}>ダウンロード</Text>
        </TouchableOpacity>

        {/* Image Preview */}
        <Image
          source={{ uri: imageUrl }}
          style={[
            styles.image,
            {
              width: Math.min(width * 0.95, height * 0.8),
              height: height * 0.8,
            },
          ]}
          resizeMode="contain"
        />
      </View>
    </Modal>
  );
};

export default ImagePreviewModal;

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 40,
    right: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    padding: 10,
    borderRadius: 30,
    zIndex: 2,
  },
  downloadContainer: {
    position: 'absolute',
    top: 40,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 30,
    zIndex: 2,

    /* Box Shadow */
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 4,
    elevation: 6,
  },
  downloadText: {
    color: 'black',
    fontSize: 13,
    fontWeight: '500',
    marginLeft: 8,
  },
  image: {
    borderRadius: 10,
  },
});
