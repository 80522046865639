export default {
  name: 'QRCode',
  displayName: { ja: 'QRコード生成', en: 'QRcode' },
  resizeX: true,
  props: [
    {
      name: 'value',
      displayName: {
        ja: 'QRコードの値',
        en: 'QRcode value',
      },
      type: 'text',
      disableDynamicValues: false,
      helpText: 'QRcode value...',
      hideCollection: false,
      hideFormInputs: false,
      isOnlyNumberField: false,
    },
    {
      name: 'onPress',
      type: 'action',
      displayName: {
        en: 'Click Actions',
        ja: 'Click Actions',
      },
    },
  ],
  resizeY: true,
};
