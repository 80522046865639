import React from 'react';
import Svg, { Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const SendIcon: React.FC<Props> = ({
  width = 24,
  height = 24,
  color = '#0175FF',
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 25" fill="none">
      <Path d="M2 21.5L23 12.5L2 3.5V10.5L17 12.5L2 14.5V21.5Z" fill={color} />
    </Svg>
  );
};

export default SendIcon;
