import { Platform } from 'react-native';
import { isEmpty } from 'lodash';
import { stripeMessage } from './locale';
import queryString from 'query-string';
import { TextAlign } from '@nocode/types/common.type';

export const getTextDecorationLine = (attributes: {
  linethrough?: boolean;
  textDecoration?: boolean;
}) => {
  let textDecorationLine:
    | undefined
    | 'underline line-through'
    | 'underline'
    | 'line-through';
  if (attributes.linethrough && attributes.textDecoration) {
    textDecorationLine = 'underline line-through';
  } else {
    if (attributes.linethrough) {
      textDecorationLine = 'line-through';
    }
    if (attributes.textDecoration) {
      textDecorationLine = 'underline';
    }
  }
  return textDecorationLine;
};

export const getShadow = (attributes: any) => {
  if (!attributes.shadow) return {};

  return {
    shadowColor: attributes.shadow.color,
    shadowRadius: attributes.shadow.size,
    shadowOpacity: attributes?.shadow?.blur || 0,
    shadowOffset: {
      width: attributes.shadow.x,
      height: attributes.shadow.y,
    },
    elevation:
      attributes?.shadow?.size === 0
        ? attributes?.shadow?.x
        : attributes?.shadow?.size,
  };
};

export const getFontWeight = (attributes: any) => {
  if (!attributes.fontWeight) return 'normal';

  return typeof attributes.fontWeight === 'string'
    ? attributes.fontWeight
    : attributes.fontWeight.toString();
};
export const checkFont = (font?: any, fontWeight?: boolean, target?: any) => {
  if (font) {
    if (Platform.OS !== 'web') {
      switch (font) {
        case 'Noto Serif, serif':
          if (fontWeight === true) {
            return 'ShipporiMincho-Bold';
          }
          return 'ShipporiMincho-Medium';
        case 'Noto Sans JP, sans-serif':
          return 'NotoSansJP-Regular';
        case 'NotoSansJP-Regular':
          return 'NotoSansJP-Regular';
        case 'Roboto':
          if (font === 'Roboto' && Platform.OS === 'android') {
            if (fontWeight === true) {
              return 'KaiseiOpti-Bold';
            }
            return 'KaiseiOpti-Regular';
          } else {
            return 'Roboto-Light';
          }
        case 'Roboto, sans-serif':
          if (font === 'Roboto, sans-serif' && Platform.OS === 'android') {
            if (fontWeight === true) {
              return 'KaiseiOpti-Bold';
            }
            return 'KaiseiOpti-Regular';
          } else {
            return 'Roboto-Light';
          }
        case 'KaiseiOpti-Regular':
          return 'KaiseiOpti-Regular';
        case 'Roboto-Light':
          return 'Roboto-Light';
        default:
          return 'Roboto-Bold';
      }
    } else {
      if (fontWeight === true && target) {
        switch (font) {
          case 'Noto Sans JP, sans-serif':
            return 'NotoSansJP-Bold';
          case 'Noto Serif, serif':
            return 'NotoSerif-Bold';
          default:
            return 'Roboto-Bold';
        }
      }
      switch (font) {
        case 'Noto Sans JP, sans-serif':
          if (target) {
            return 'NotoSansJP-Regular';
          } else {
            return 'Noto Sans JP';
          }
        case 'Noto Serif, serif':
          return 'NotoSerif-Regular';
        default:
          return 'Roboto';
      }
    }
  }
  return 'Roboto';
};

export const checkType = (text: any) => {
  var regVisa = new RegExp('^4[0-9]$');
  var regMaster = new RegExp('^5[1-5]|2[34567]|5[1-5]$');
  var regExpress = new RegExp('^3[47]$');
  var regDiners = new RegExp('^3[0689]$');
  var regDiscover = new RegExp('^6[046]$');
  var regJCB = new RegExp('^35$');

  if (regVisa.test(text)) return 'VISA';
  else if (regMaster.test(text)) return 'MASTER';
  else if (regExpress.test(text)) return 'AEXPRESS';
  else if (regDiners.test(text)) return 'DINERS';
  else if (regDiscover.test(text)) return 'DISCOVERS';
  else if (regJCB.test(text)) return 'JCB';
  else return 'invalid';
};

export const checkMessage = (
  testPk: any,
  testEnabled: any,
  testSk: any,
  locale: any
) => {
  if (isEmpty(testPk) && testEnabled === true) {
    return stripeMessage({ locale, type: 'publishable_key' });
  }
  if (
    testPk !== '' &&
    testEnabled === true &&
    !String(testPk)?.startsWith('pk_test')
  ) {
    return stripeMessage({ locale, type: 'publishable_key_invalid' });
  }
  if (isEmpty(testSk) && testEnabled === true) {
    return stripeMessage({ locale, type: 'secret_key' });
  }
  if (
    testSk !== '' &&
    testEnabled === true &&
    !String(testSk).startsWith('sk_test')
  ) {
    return stripeMessage({ locale, type: 'secret_key_invalid' });
  }
};

export const Conversions: Record<string, string> = {
  '&nbsp;': '\u00A0',
  '&gt;': '>',
  '&lt;': '<',
  '&amp;': '&',
} as const;

export const parseText = (val: string | Record<string, any>) =>
  typeof val === 'string'
    ? val.replace(/&(nbsp|amp|quot|lt|gt);/g, (i: string) => Conversions[i])
    : Array.isArray(val)
    ? val[0].replace(/&(nbsp|amp|quot|lt|gt);/g, (i: string) => Conversions[i])
    : 'Error';

export const getBorderStyle = (
  borderStyle: 'solid' | 'dotted' | 'dashed' | 'none'
) => (borderStyle !== 'none' ? borderStyle : undefined);

export const getBorderWidth = (
  borderWidth: number,
  borderStyle: 'solid' | 'dotted' | 'dashed' | 'none'
) => (borderStyle !== 'none' ? borderWidth : 0);

export const checkFontWeight = (
  fontWeightBold: boolean,
  fontFamily: string
): 'bold' | 'normal' =>
  fontWeightBold &&
  (fontFamily !== 'Roboto, sans-serif' || Platform.OS !== 'android')
    ? 'bold'
    : 'normal';

export const checkStyles = (styles: {
  textDecorationUnderline?: boolean;
  textDecorationLineThrough?: boolean;
  fontFamily?: string;
  fontWeightBold?: boolean;
  textAlignment?: TextAlign;
  fontStyleItalic?: boolean;
  backgroundColor?: string;
  borderRadius?: number;
  color?: string;
  fontSize?: number;
}) => {
  const {
    textDecorationUnderline,
    textDecorationLineThrough,
    fontFamily = '',
    fontWeightBold = false,
    textAlignment,
    fontStyleItalic,
    backgroundColor,
    borderRadius,
    color,
    fontSize,
  } = styles || {};

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;

  const checkFontFamily =
    !fontWeightBold && !fontStyleItalic
      ? checkFont(fontFamily, fontWeightBold, target)
      : 'Roboto';

  const textStyle = getTextDecorationLine({
    textDecoration: textDecorationUnderline,
    linethrough: textDecorationLineThrough,
  });

  const checkFontStyle = (fontStyleItalic ? 'italic' : 'normal') as
    | 'normal'
    | 'italic';

  const checkFontWeightStyle = checkFontWeight(fontWeightBold, fontFamily);

  return {
    textAlign: textAlignment,
    textDecorationLine: textStyle,
    fontStyle: checkFontStyle,
    fontWeight: checkFontWeightStyle,
    backgroundColor,
    borderRadius,
    color,
    fontSize,
    ...(checkFontStyle === 'normal' && { fontFamily: checkFontFamily }),
  };
};

export const getTextOptions = (value: any): string => {
  if (!value) return '';
  if (typeof value === 'string') return value;
  if (Array.isArray(value)) return value.join('');
  return `${value}`;
};

export const getListItems = (items: any[], len = 3, columnCount?: number) => {
  if (!Array.isArray(items) || !items.length) return [];
  const item = items[0];

  const value = Object.keys(item)
    .map((key) => {
      if (item[key] && typeof item[key] === 'object' && item[key].text) {
        return { [key]: item[key].text };
      }

      return { [key]: item[key] };
    })
    .reduce((prev, current) => ({ ...prev, ...current }), {});

  const list = Array.from(Array(len), () => value);

  return columnCount === 1
    ? list.slice(0, 2)
    : columnCount === 3
    ? list.slice(0, 3)
    : list;
};
