export const URI_IMAGE_CARD = {
  visa: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAMAAAA7+k+nAAABCFBMVEWGhoaLi4vQ0NDQ0NDQ0NDR0dHf39/c3Nzd3d3e3t4UNMsXNswYN8waOcwbOs0dPM0gPs4hP84jQc4kQs4lQs8mQ88oRs8pRtAvS9EzT9I0UNI2UdM9V9RBW9VHYNZJYddLZNdNZdhOZthRadhWbdpgddxrf95xhOB3ieF4i+F6jOKCk+OJmeWPnuaRoOeVo+iYpuicqumdq+mfrOqmsuuns+uuue2wu+2xu+60v+61v++2wO+3we+6w/DAyfHCyvLEzPLIz/PR1/XT2fXW2/bZ3vfa3/fb4Pfe4vjf4/ji5vnt8Pvw8vzz9fz19v329/33+P35+f75+v76+/78/f79/f/+/v////8VmGQaAAAACnRSTlMVFpeYmZrBwsXGrRAbdQAAAAFiS0dEV30K2R8AAAC+SURBVBjTfdDHFoJADAXQQaxRUexYUey9d1FR7L2Q//8TF+Ny9G3vyTtJCGe2McJzxILM8MTOBjtxsMHxB2Y5qYdrqVqRjoukENpgO5qnEydXFkuwTgXv3li/db4GXH6DVsnBm18xxMxF9DRe2IYy6BRqUIXpAYq4SkL2HZNX0KWggRB9z6Czez4i4gQEHxQoGGFoYh2WiltwNtLiYJiIf9fV1Atu1dt+PDq8VA1Rn/+74+dLzGwwEY63MmIiH2fsVWlgWCJhAAAAAElFTkSuQmCC',
  mastercard:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAMAAAA7+k+nAAAAjVBMVEUjIyMmJiYlJSUlJSUlJSUlJSUlJSVfGiJgGiJhGiJjGiJjSCJkSSJlSiJnSyKNEiCOER+RYyCTZCC4CR24Ch65CR26CR3Bfx3Bfx7Cfx3DgB3iAhzjAhzrABvrlxzsmBztmRzumRz0PhP3nhv4WQ35Wg35jBP7fg38ZQf9cAf+YgL+YwL/XwD/YgH///8pHMvNAAAABnRSTlNISUvi4+TsCegUAAAAAWJLR0QuVNMQhwAAAIZJREFUGNN10NcSgjAQBdAgMVRFQJqhSlXX//89h1UCccJ93DOzjRyOTBGqEcqU0QnbyQ/sOM8j63xrmuy0hUsxpx/bOcEKNtbvAAOKKyBGmAAeCIkAjvAGeCFUf/BcoBQQya1SAZY83FnX9VC677r+9kAz5PxquEldp450ueIlu0/UqLr+ARauFxrHU83zAAAAAElFTkSuQmCC',
  'american-express':
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAMAAAA7+k+nAAABHVBMVEUAbtEAcNIAcM8Bb9ABb9ABb9ABb9ACcNAEcdEGctEHctEKdNINdtIQd9MSedMWe9QZfdQbftUef9UfgNYmhNcohdcqhtgvidgwitk0jNk2jdo3jdo5j9o7kNs9kds+kdtAk9xClNxDlNxFld1Hl91Il91Nmt5Omt5Om95QnN9Snd9Wn+BYoOBepOFkp+JnqeNsq+RxruVzr+R1seV5s+Z+tud/t+aHu+iMvuqNv+mRwOqRweqUwuqUw+qZxeyaxuuaxuyfyOyhyu2mzO6mze6nze6pzu+r0O+s0O+s0PCu0e+z0/C31vC41vG41/K51/G92vK+2vLL4vTN4/XP4/XQ5fXX6Pfa6vfp8/rs9Pru9fv1+fz5/P3///7////oBN9WAAAABnRSTlNISUvi4+TsCegUAAAAAWJLR0ReBNZhuwAAALRJREFUGNNjYGJlwwJYGBlY2LACZgZUvra+uoS+voK4vi6qhIizspulgbKvh4oGqoSNqKhEpDSXoSMXP4oEnzunC3uAtJaAoiaqhImSsKSgmrStEKcrigRnqHZYbJCOkb+9WbAOsgSftmmghYWFn1VUjIMnquVyhjKurhFa4upSTugSHNzc4dH8nCE86BKyXl5RemKySnZoEtaysrLm8j7eqsaoEgL6YCDACwwSnIHIyIJdHACISx3CYtE+/AAAAABJRU5ErkJggg==',
  'diners-club':
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAMAAAA7+k+nAAABF1BMVEWcnJyfn5/W1tbW1tbX19fX19ff39/c3Nze3t4BZawCZqwFZ60Jaq8MbLAQbrERb7ESb7IVcbMXcrMYc7MbdbUjercme7gpfbksf7oxgrwygrwyg7wzg7w0hL03hr46h79EjcJGj8NSlsZSl8ZYmshdncpencpgnsthn8tjoMxmos1wqNB7r9R8r9R9sNR+sNWKuNmLudmNutqPu9uSvdyUvtyZwd6dxN+fxeCkyOGlyeKoyuOpy+Ox0Oa/2OrA2erB2uvD2+vI3u3J3u3L4O7Q4/DR4/DT5PHX5/Lf7PXg7PXh7fXj7vbm8Pfn8ffs9Pnu9fnv9frx9/ry9/vz+Pv1+fz3+vz5+/36/P37/f79/v7+/v////8moVHRAAAACXRSTlMkJaipqqvBwsbs484eAAAAAWJLR0Rc6tgAlwAAANhJREFUGNNjYGRlxwJYGBmYY7ACJgYOCMNVXVpKREBAVMMNwueAShgoOkXHRNiKc3JyGyJLmGtGBQXG+EYEy3NyclogJIJlw92FXWLMpP29eTg5BUPgEtaG0TKcQAlOrRgVoBYbuISenQ8nWEI4xhAooY+QsPeCSAjGGAEldOESlsbREmAJtRhVoIQVXCJQLtKZDygh5uvLy8nJH4hwrol2tJ9/jGdomAJQgymyB3WUPWJioh1kOTm5dFF8HuOoJCkrxMkpoOQICxJEIAYAAVIgMjKzYQHMDAC+p1JVUk8eNgAAAABJRU5ErkJggg==',
  discover:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAMAAAA7+k+nAAABLFBMVEWfn5/bgjbW1tbW1tbX19fX19ff39/c3Nzf3dre3t7a2trOzs7g0MO1tbXkw6aurq7qp3HujT7vfiDvfiHxehfxeBU3NzdFRUVISEhLS0tOTk5PT09SUlJdXV1jY2NlZWVnZ2doaGhpaWltbW1wcHB2dnZ3d3d4eHh6enp/f3+BgYGCgoKEhISFhYWHh4eKioqMjIyNjY2RkZGSkpKkpKSlpaWqqqqsrKyurq6vr6+wsLCysbGysrK2trbBwcHExMTJycnMzMzQ0NDSysPS0tLY2NjZ2dna2trf39/i4uLj4+Pp6ent7e3x8fHydxLyeBPyeRbzfh7zgSP0hy70kD708e71m1D3rnP3sHX4toH5wpT5+fn6yqP707L95tP96tr+8OT+9e3+9vD///8xgi2+AAAAFnRSTlMlZKipqqvBwsLGx8nMzs/Q3u719v3+E5lv1gAAAAFiS0dEY1y+LaoAAACnSURBVBjTY2BgZccCWBgYmJOxAiYGTuwSnNSU4JVQcpHXU/HQULYOD44ytfFSl1YzltPlZODX8JWRMlK01EiO8/MLCJVSdRJT05dXAErI6htImehoSdo5B/mFJGvKJIs7GqpoczJwmzsl27tbRXtbeCbEJiX7uCbbmrn5OHCR7Coe7BKJkSLYJOIjAv38MAIxMSbM38/PTwgYvGxwwMEnICjqBwLCjAB1HWx5nNoocQAAAABJRU5ErkJggg==',
  jcb: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAQCAMAAADK8RQqAAABNVBMVEUAAAAAgL/MMwBduS5juDnZMwAAe7AFe7LVLQZotTdotjYDe7FntjbTLQYEerHULQZntjcEebEEerHULQYEerIEerFotjhmtjd8utaBvNjULQbULQbdWToEerFntjYEerFntjcEerFntjfULQYEerFntjf66eX66ubM5rsEerERgbUki7s1lMA8mMJOochSo8lntjdqtzt0vEh2vUuAwliBvNiFxV+Txt2VzHOYzXeaznqcyuCg0YKn1Yyr0+Ww1eex2Ziz2pu22Oi23J+62uq/4KrB4a3F47LM5O/M57zULQbW6fLZRCHZRyTZ7c3a6/PeXT/h8Nfi8dnjeF/keWDlgmvmhm/niHLo9ODuqpruq5vxuazywLXywbb1z8b10Mf1+vL21c35/Pf8/fv++vn++/r////QuvWpAAAAKXRSTlMABAoLEhQdZn6RlpqkqbCwuL3ByMnKyuDg4eHi6Pf4+fn6+vv8/Pz8/U+q0u8AAAABYktHRGYs1NklAAAAwUlEQVQYGQXBsU7CQAAA0HfH0WoLEUkwuhgdjP6D8fOdjaMTkzKABClIOSr4XgjVeEA7jfXVI++faTCp+UrFLVC8AMUdiCWgBgyBWABKwDkQAyAAIpDQG+9bYdSPXaa4oV2JiE/XVNVm0Rx5buYXD6UEEHY7fxl1OptnEUEHgC6vL0upl44jK07Vvk195K5LQylMiu5ty++mGh4yr/eDw3QpdTPg9APkGYgAAIAIHAEAItAA1oAmweF7Cz4WLVgu8z9KJEEQEE4OmwAAAABJRU5ErkJggg==',
  unionpay:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABmJLR0QA/wD/AP+gvaeTAAADQElEQVQ4jYWUTWxbRRSFvxk/x8/2ix2rManTNDh2EkRaaKQmDiAk/oSQQEIsyK4VrNgVJKSKbmCFEIJdF6jKAgQUxIIfCSEIBYFYIIpjKpKmdkQTO01IUjtOnD/Hdp79hkXbh+NY6lnNnHfuPffdmbmCOqSj0feU4Ayg3+H+0AK8rfexLpz1UpQG270OTJ8AwQEISFWlHrM/zUUiQ0gxsS8J8IIxTE60HEiwe0RS6pIHM9ehJuWArRBCPN4oWJJ60+QAVV+TsvejTFXO2gaWYLhRkZStzUMFVL13NfiLsTHz/z9QxLp+vIT0egld/AykJOkwmlevC0ZHYoy/+jp/nnuTwz5/kxpEHEADmI1G79GOdIZVpQxSoioV3A89jEvv4QnNYG7hJv09nVyducHx+7rJyV1i4R4+j1/mtSefpq+jg3B7O8VKhbJpUjZNFtfXJmwDKcSI69gxKtPTuAYHKU9NEjh7lpc0g8t/X+cp9SDBQ35Gn3uEgN9gkwrBoJ/dvT3O//ozrzz6GCeOdvPLTIrhe8M8/8F5asqKA9xqkVLD1WyWlvsHaH1xlMpEgpUrk8wvrdIXDrGxWcSju0gvZEnN/ksyuwzAW99+QyafJ+DxkpjPsLxRYDw5zVpxZ52xj9O3jwtme6PjAp6p7+HXzhDv6xF77zPcVGsWbo+L/APgdGpUaxZmrYbPrZPf2WHs1Muc+eIim6XSD7ULHz4LoCkQGRhSDYeUcnjttdOp8c4bp1lczjN4IkLRVSN5c5lIe5A2t4eyaZIvbhOfT7NZKiEgfidWpiORXgWHGm/BtMNnrw2PzshgP20+L12hdnSnk++mJulo9dFuGGTWVjnZHeb3udnbHbdsA01IOaLYX/8uDhakPS3oOdrBSq6Ax+3ip6mrBII+Dvt9ZLe3WNooMBDqJHFjnpmVFQBqopawDVBquHGWpDQDq47MF7ZIXl8kvZDF3W/wT2YVKSS57S2Ugkupa3T62yiZewBpLnyasw0sQazxTV5reMELS6uce/cTLA0KJzWb/+pKgiaI12+kgOONirTD3SwQy33X8YBS/LbPQMH3gFVPDlW3cKl91C1xWeEoN943G1XgS6vF+1E9+R9VXSsbBh+bPQAAAABJRU5ErkJggg==',
  maestro:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAVBAMAAADV4/HZAAAAJ1BMVEUAAABJbZJAYIBVVY5NZoBOXINVYoNSYYBQX4JPXoJRXYFQXYD///8Swwt6AAAADHRSTlMABwgJCicnMjNkZXDWg+RVAAAAAWJLR0QMgbNRYwAAAFBJREFUGNNjYCAI1Co6EKBMgYEhowMZBDAwVKAIFDAwoPA7GrAJNDMwsKAIADEDiQIt6GYQthZDoGv3LjiCCuyEI6xaMHwbgR4eoshKSgUYAI4IjxondF69AAAAAElFTkSuQmCC',
  elo: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAVBAMAAADV4/HZAAAAJ1BMVEUAAABJbZJAYIBVVY5NZoBOXINVYoNSYYBQX4JPXoJRXYFQXYD///8Swwt6AAAADHRSTlMABwgJCicnMjNkZXDWg+RVAAAAAWJLR0QMgbNRYwAAAFBJREFUGNNjYCAI1Co6EKBMgYEhowMZBDAwVKAIFDAwoPA7GrAJNDMwsKAIADEDiQIt6GYQthZDoGv3LjiCCuyEI6xaMHwbgR4eoshKSgUYAI4IjxondF69AAAAAElFTkSuQmCC',
  mir: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAVBAMAAADV4/HZAAAAJ1BMVEUAAABJbZJAYIBVVY5NZoBOXINVYoNSYYBQX4JPXoJRXYFQXYD///8Swwt6AAAADHRSTlMABwgJCicnMjNkZXDWg+RVAAAAAWJLR0QMgbNRYwAAAFBJREFUGNNjYCAI1Co6EKBMgYEhowMZBDAwVKAIFDAwoPA7GrAJNDMwsKAIADEDiQIt6GYQthZDoGv3LjiCCuyEI6xaMHwbgR4eoshKSgUYAI4IjxondF69AAAAAElFTkSuQmCC',
  hiper:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAVBAMAAADV4/HZAAAAJ1BMVEUAAABJbZJAYIBVVY5NZoBOXINVYoNSYYBQX4JPXoJRXYFQXYD///8Swwt6AAAADHRSTlMABwgJCicnMjNkZXDWg+RVAAAAAWJLR0QMgbNRYwAAAFBJREFUGNNjYCAI1Co6EKBMgYEhowMZBDAwVKAIFDAwoPA7GrAJNDMwsKAIADEDiQIt6GYQthZDoGv3LjiCCuyEI6xaMHwbgR4eoshKSgUYAI4IjxondF69AAAAAElFTkSuQmCC',
  hipercard:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAVBAMAAADV4/HZAAAAJ1BMVEUAAABJbZJAYIBVVY5NZoBOXINVYoNSYYBQX4JPXoJRXYFQXYD///8Swwt6AAAADHRSTlMABwgJCicnMjNkZXDWg+RVAAAAAWJLR0QMgbNRYwAAAFBJREFUGNNjYCAI1Co6EKBMgYEhowMZBDAwVKAIFDAwoPA7GrAJNDMwsKAIADEDiQIt6GYQthZDoGv3LjiCCuyEI6xaMHwbgR4eoshKSgUYAI4IjxondF69AAAAAElFTkSuQmCC',
  default:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAVBAMAAADV4/HZAAAAJ1BMVEUAAABJbZJAYIBVVY5NZoBOXINVYoNSYYBQX4JPXoJRXYFQXYD///8Swwt6AAAADHRSTlMABwgJCicnMjNkZXDWg+RVAAAAAWJLR0QMgbNRYwAAAFBJREFUGNNjYCAI1Co6EKBMgYEhowMZBDAwVKAIFDAwoPA7GrAJNDMwsKAIADEDiQIt6GYQthZDoGv3LjiCCuyEI6xaMHwbgR4eoshKSgUYAI4IjxondF69AAAAAElFTkSuQmCC',

  invalid:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAVCAMAAAAQExzYAAAAqFBMVEUAAAD/JCTfICD/HBzmGhrmGibnGCToFyPrGifrHyTrHijpGijqHibqHSboHibpHibpGiXsHCXqGyfqGyftHybrHibtHibsHSfsHSbsHSbrGyfsHCfqHCfrHCbrGyXsGyfsGyfrHCbrHCbrHCfsHCfrHCbrHSfrHCbrHCbrHCfrHCfsHCbrHCbrHCbrHCbrGybrGybrHCbqHCXrHCXrHCbrHCbrHCb///87xzc7AAAANnRSTlMABwgJChQVFicyMzo8PUNERVJUVWRlZWlqa3B2d4GCg4Sam5ydzM3Oz9fZ2fDx8vP09PX1+/6lei9FAAAAAWJLR0Q3MLi4RwAAAM5JREFUKM99kNcagjAMhYOilIoDFRXcE3HgAPr+j2ZSRREK5yL52vMnTQqgkrE6Ph7+0oASDUIhFfbxUGMmz2mWoNmyMCRIsLzNe0+qBqB4bUKhnu/EDxALKPj8nAV8BRBlgZCAuhxdTwE5grDaMl0J+CyXAieRka8AtmTE40lMea4A5Jp7gAOmS4Ou9f8Z+BQ/KnZd7JDYoNgCift7gJsNaoB3N0EUBesOlAGpPsDI8zxHEb+Ag8ehIn6B6ifMCt8kgFUAjACNlfUwmYb+C8x5LmP7DuTPAAAAAElFTkSuQmCC',
};

export type URI_IMAGE_CARD = keyof typeof URI_IMAGE_CARD;

const CARDS = {
  visa: {
    niceType: 'Visa',
    type: 'visa',
    patterns: [4],
    gaps: [4, 8, 12],
    lengths: [16, 18, 19],
    code: { name: 'CVV', size: 3 },
  },
  mastercard: {
    niceType: 'Mastercard',
    type: 'mastercard',
    patterns: [[51, 55], [2221, 2229], [223, 229], [23, 26], [270, 271], 2720],
    gaps: [4, 8, 12],
    lengths: [16, 19],
    code: { name: 'CVC', size: 3 },
  },
  'american-express': {
    niceType: 'American Express',
    type: 'american-express',
    patterns: [34, 37],
    gaps: [4, 10],
    lengths: [15, 17],
    code: { name: 'CID', size: 4 },
  },
  'diners-club': {
    niceType: 'Diners Club',
    type: 'diners-club',
    patterns: [[300, 305], 36, 38, 39],
    gaps: [4, 10],
    lengths: [14, 16, 19],
    code: { name: 'CVV', size: 3 },
  },
  discover: {
    niceType: 'Discover',
    type: 'discover',
    patterns: [6011, [644, 649], 65],
    gaps: [4, 8, 12],
    lengths: [16, 19],
    code: { name: 'CID', size: 3 },
  },
  jcb: {
    niceType: 'JCB',
    type: 'jcb',
    patterns: [2131, 1800, [3528, 3589]],
    gaps: [4, 8, 12],
    lengths: [16, 17, 18, 19],
    code: { name: 'CVV', size: 3 },
  },
  unionpay: {
    niceType: 'UnionPay',
    type: 'unionpay',
    patterns: [
      620,
      [624, 626],
      [62100, 62182],
      [62184, 62187],
      [62185, 62197],
      [62200, 62205],
      [622010, 622999],
      622018,
      [622019, 622999],
      [62207, 62209],
      [622126, 622925],
      [623, 626],
      6270,
      6272,
      6276,
      [627700, 627779],
      [627781, 627799],
      [6282, 6289],
      6291,
      6292,
      810,
      [8110, 8131],
      [8132, 8151],
      [8152, 8163],
      [8164, 8171],
    ],
    gaps: [4, 8, 12],
    lengths: [14, 15, 16, 17, 18, 19],
    code: { name: 'CVN', size: 3 },
  },
  maestro: {
    niceType: 'Maestro',
    type: 'maestro',
    patterns: [
      493698,
      [5e5, 504174],
      [504176, 506698],
      [506779, 508999],
      [56, 59],
      63,
      67,
      6,
    ],
    gaps: [4, 8, 12],
    lengths: [12, 13, 14, 15, 16, 17, 18, 19],
    code: { name: 'CVC', size: 3 },
  },
  elo: {
    niceType: 'Elo',
    type: 'elo',
    patterns: [
      401178,
      401179,
      438935,
      457631,
      457632,
      431274,
      451416,
      457393,
      504175,
      [506699, 506778],
      [509e3, 509999],
      627780,
      636297,
      636368,
      [650031, 650033],
      [650035, 650051],
      [650405, 650439],
      [650485, 650538],
      [650541, 650598],
      [650700, 650718],
      [650720, 650727],
      [650901, 650978],
      [651652, 651679],
      [655e3, 655019],
      [655021, 655058],
    ],
    gaps: [4, 8, 12],
    lengths: [16, 19],
    code: { name: 'CVE', size: 3 },
  },
  mir: {
    niceType: 'Mir',
    type: 'mir',
    patterns: [[2200, 2204]],
    gaps: [4, 8, 12],
    lengths: [16, 17, 18, 19],
    code: { name: 'CVP2', size: 3 },
  },
  hiper: {
    niceType: 'Hiper',
    type: 'hiper',
    patterns: [637095, 63737423, 63743358, 637568, 637599, 637609, 637612],
    gaps: [4, 8, 12],
    lengths: [16, 19],
    code: { name: 'CVC', size: 3 },
  },
  hipercard: {
    niceType: 'Hipercard',
    type: 'hipercard',
    patterns: [606282],
    gaps: [4, 8, 12],
    lengths: [16, 19],
    code: { name: 'CVC', size: 3 },
  },
};

export type CARDS = typeof CARDS[keyof typeof CARDS];

const CARD_INVALID = {
  niceType: 'Invalid',
  type: 'invalid',
  patterns: null,
  gaps: null,
  lengths: [16, 19],
  code: {
    name: 'CVC',
    size: 3,
  },
  matchStrength: null,
};

export const checkTypeCardPayment = (text: string) => {
  const matches = (text: string, regCard: number | string) => {
    return Array.isArray(regCard)
      ? ((text, regCard, result) => {
          const regCardLength = String(regCard).length,
            regCardLengthSubstr = text.substr(0, regCardLength),
            regCardLengthSubstrInt = parseInt(regCardLengthSubstr, 10);
          regCard = parseInt(
            String(regCard).substr(0, regCardLengthSubstr.length),
            10
          );
          result = parseInt(
            String(result).substr(0, regCardLengthSubstr.length),
            10
          );
          return (
            regCardLengthSubstrInt >= regCard &&
            regCardLengthSubstrInt <= result
          );
        })(text, regCard[0], regCard[1])
      : ((text, regCard) => {
          regCard = String(regCard).substring(0, text.length);
          return regCard === text.substring(0, regCard.length);
        })(text, regCard);
  };

  const addMatchingCardsToResults = (text: string, card, result) => {
    for (let o = 0; o < card.patterns.length; o++) {
      var regCard = card.patterns[o];
      if (matches(text, regCard)) {
        let cardClone = { ...card };
        const regCardLength = Array.isArray(regCard)
          ? String(regCard[0]).length
          : String(regCard).length;
        if (text.length >= regCardLength) {
          cardClone.matchStrength = regCardLength;
          result.push(cardClone);
        }
        break;
      }
    }
  };

  const findBestMatch = (result) => {
    return ((result) => {
      let resultFilter = result.filter((result) => result.matchStrength).length;
      return resultFilter > 0 && resultFilter === result.length;
    })(result)
      ? result.reduce((result, currentResult) =>
          result
            ? Number(result.matchStrength) < Number(currentResult.matchStrength)
              ? currentResult
              : result
            : currentResult
        )
      : null;
  };

  const checkType = (text: string) => {
    const result = [];
    Object.values(CARDS).forEach(function (card) {
      addMatchingCardsToResults(text, card, result);
    });
    let resultFind = findBestMatch(result);
    return resultFind || result[0];
  };

  const checkTypeResult = checkType(text);
  return checkTypeResult ? checkTypeResult : CARD_INVALID;
};
