import { ChatComponentType } from '@common/hooks/useChatComponentActions';
import moment from 'moment';
import React, { Fragment } from 'react';
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { ChatComponentState } from '../context';
import { UseChatComponentProps } from '../hook';
import ChevronLeftIcon from '@common/components/Icons/ChevronLeftIcon';
import { FileList } from './FileList';
import { InputMessage } from './InputMessage';
import { SpHeader } from './SpHeader';

export const MessageHistory = ({
  messages,
  groupName,
  showBackButton,
  context,
  hookActions,
}: {
  groupName: string;
  messages: ChatComponentType.ChatMessagesTypeWithMessageJson[];
  showBackButton?: boolean;
  context: Partial<ChatComponentState>;
  hookActions: Partial<UseChatComponentProps>;
}) => {
  const { listRef, filesValue, unreadMessageStatus } = context;
  const { onSelectGroup, fetchMessageHistoryNextPage, makeReadStatus } =
    hookActions;
  return (
    <View
      style={[
        styles.container,
        {
          paddingBottom: filesValue?.length ? 132 : 60,
        },
      ]}
      nativeID="MessageHistory"
    >
      <View style={styles.headerContainer}>
        <TouchableOpacity
          onPress={() => {
            onSelectGroup?.(null);
          }}
          style={[
            styles.backButtonContainer,
            {
              display: showBackButton ? 'flex' : 'none',
            },
          ]}
        >
          <ChevronLeftIcon />
        </TouchableOpacity>
        <SpHeader text={groupName} />
      </View>
      <FlatList
        ref={listRef}
        style={styles.messageHistoryContainer}
        nativeID="list-message"
        onScroll={(e) => {
          const isScrollToTop = e.nativeEvent.contentOffset.y === 0;
          if (isScrollToTop) {
            fetchMessageHistoryNextPage?.();
          }
        }}
        data={messages}
        nestedScrollEnabled
        renderItem={({ item: message }) => {
          // perform read when there are unread messages
          if (unreadMessageStatus?.[message.groupId]) {
            makeReadStatus?.({ messageId: message.id });
          }
          const { files, text } = message.message;
          return (
            <View key={message.id} style={styles.commentContainer}>
              <Image
                source={{ uri: message?.senderAvatar?.url }}
                style={styles.messageAvatar}
              />
              <View style={styles.commentBox}>
                <View style={styles.commentHeader}>
                  <Text style={styles.username}>{message?.senderName}</Text>
                  <Text style={styles.time}>
                    {moment(message.createdAt).format('YYYY-MM-DD HH:mm')}
                  </Text>
                </View>
                {text ? (
                  <Text style={styles.commentText}>{text}</Text>
                ) : (
                  <Fragment />
                )}

                {files?.length ? (
                  <FileList files={files} context={context} />
                ) : (
                  <Fragment />
                )}
              </View>
            </View>
          );
        }}
      />
      <InputMessage context={context} hookActions={hookActions} />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontalHorizontal: 4,
    borderRadius: 4,
    backgroundColor: '#F2F5F7',
    position: 'relative',
    height: '100%',
  },
  headerContainer: {
    position: 'relative',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  backButtonContainer: {
    position: 'absolute',
    top: 17,
    left: 17,
    width: 24,
    height: 24,
    borderRadius: 24,
  },
  messageHistoryContainer: {
    paddingHorizontal: 20,
    paddingBottom: 40,
  },
  commentContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  messageAvatar: {
    width: 36,
    height: 36,
    borderRadius: 24,
    backgroundColor: '#F2F5F7',
  },
  commentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  username: {
    fontSize: 13,
    fontWeight: '700',
    color: '#1A2737',
  },
  time: {
    fontSize: 11,
    fontWeight: '400',
    color: '#ADB6B9',
  },
  commentBox: {
    flex: 1,
    marginLeft: 12,
    padding: 8,
    backgroundColor: 'white',
    borderRadius: 4,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.05,
    shadowRadius: 20,
    elevation: 3,
  },
  commentText: {
    fontSize: 13,
    fontWeight: '400',
    color: '#1A2737',
  },
});
