import { IBookingCalendar } from '@nocode/types';
import { BindingItemTypeFormatted } from '@nocode/types/bookingCalendar.type';
import { get, pick } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { useCallback, useMemo } from 'react';
import {
  FlatList,
  Platform,
  ScrollView,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import { IconButton } from 'react-native-paper';
import { checkStyles, getBorderStyle, getFontWeight } from '../func';
import { isCheckColor } from '../shared';
import { RATIO_TEXT_CONTENT, useBookingCalendar } from './hook';

const dayNameSort = ['日', '月', '火', '水', '木', '金', '土'];

const HeaderTitle: React.FC<{
  onPreviousWeek: VoidFunction;
  onNextWeek: VoidFunction;
  beginDate: Date;
  titleStyle: StyleProp<TextStyle>;
  iconSize: number;
}> = ({ beginDate, onNextWeek, onPreviousWeek, titleStyle, iconSize }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    nativeID={'booking-calendar-header-title'}
  >
    <IconButton
      {...{
        icon: 'chevron-left',
        color: '#fff',
        size: iconSize,
        onPress: onPreviousWeek,
        style: {
          backgroundColor: '#c3c3c3',
        },
      }}
    />
    <Text style={[titleStyle, { marginHorizontal: 10 }]}>
      {moment(beginDate).format('YYYY-MM-DD')} (
      {dayNameSort[moment(beginDate).get('day')]})
    </Text>
    <IconButton
      {...{
        icon: 'chevron-right',
        onPress: onNextWeek,
        size: iconSize,
        color: '#fff',
        style: {
          backgroundColor: '#c3c3c3',
        },
      }}
    />
  </View>
);

const BookingCalendar: React.FC<IBookingCalendar> = (props) => {
  const {
    borderColor,
    borderStyle,
    borderWidth,
    lineHeight,
    styles: customStyles,
    dayViewNumber = '7days',
    sumAvailableStock = false,
    sortBy = 'updatedTimeDesc',
  } = props.attributes;
  const isCanvas =
    Platform.OS === 'web' &&
    !queryString.parse(window?.location?.search)?.target;
  const boxWidth = useMemo(() => {
    if (customStyles.enabled && customStyles.dayWidth) {
      return customStyles.dayWidth;
    }
    switch (dayViewNumber) {
      case '3days':
        return props.width / 5;
      case '4days':
        return props.width / 6;
      case '5days':
        return props.width / 7;
      case '7days':
        return props.width / 9;
      case '1month':
        return props.width / 9;
    }
  }, [props.width, dayViewNumber, customStyles]);
  const boxHeight = lineHeight;
  const borderStyleFormatted = getBorderStyle(borderStyle);

  const styles = StyleSheet.create({
    container: {
      ...pick(props, ['width', 'height', 'zIndex']),
      ...pick(props.attributes, ['opacity']),
      backgroundColor: isCheckColor(props.attributes.backgroundColor)
        ? props.attributes.backgroundColor
        : undefined,
      display: 'flex',
    },
    text: {
      ...checkStyles(
        pick(props.attributes, ['fontFamily', 'fontSize', 'color'])
      ),
      lineHeight,
    },
    headerCalendarText: {
      fontSize: props.attributes.fontSize * RATIO_TEXT_CONTENT,
      fontWeight: getFontWeight(props.attributes),
      lineHeight: lineHeight * RATIO_TEXT_CONTENT,
    },
    contentCalendarText: {
      fontSize: props.attributes.fontSize * RATIO_TEXT_CONTENT,
      lineHeight: lineHeight * RATIO_TEXT_CONTENT,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    boxContentContainer: {
      width: boxWidth,
      height: boxHeight,
      borderColor,
      borderRightWidth: borderWidth,
      borderBottomWidth: borderWidth,
      borderStyle:
        borderStyleFormatted && Platform.OS !== 'web'
          ? 'solid'
          : borderStyleFormatted,
      overflow: 'hidden',
      justifyContent: 'center',
    },
    timeLabelBox: {
      width: boxWidth * 2,
      height: boxHeight,
      borderColor,
      borderRightWidth: borderWidth,
      borderBottomWidth: borderWidth,
      borderStyle:
        borderStyleFormatted && Platform.OS !== 'web'
          ? 'solid'
          : borderStyleFormatted,
      overflow: 'hidden',
      alignItems: 'flex-end',
      justifyContent: 'center',
      display: 'flex',
      paddingEnd: 8,
    },
    headerCalendarContainer: {
      height: boxHeight * 2,
      display: 'flex',
      flexDirection: 'row',
    },
    headerCalendarDayBox: {
      width: boxWidth,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderColor,
      borderRightWidth: borderWidth,
      borderBottomWidth: borderWidth,
    },
    timeDivider: {
      height: 3,
      backgroundColor: borderColor,
    },
    holidayTitle: {
      color: get(customStyles, 'holidayColor', '#d0021b'),
    },
    saturdayTitle: {
      color: get(customStyles, 'saturdayColor', '#4a90e2'),
    },
    containerBody: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      width: props.width,
      overflow: 'hidden',
      borderColor,
      borderWidth: borderWidth,
      borderTopWidth: borderWidth,
      borderBottomWidth: borderWidth,
      borderStyle: 'solid',
    },
    blankBox: {
      height: boxHeight * 2,
      width: boxWidth * 2,
      backgroundColor: isCheckColor(props.attributes.backgroundColor)
        ? props.attributes.backgroundColor
        : undefined,
      borderColor,
      borderBottomWidth: borderWidth,
      borderRightWidth: borderWidth,
    },
  });
  const {
    durationMapping,
    beginDate,
    onNextPage,
    onPreviousPage,
    handlePress,
    initializeTimeIndex,
    leftRef,
    rightRef,
    holidays,
    numberOfDaysDisplayed,
  } = useBookingCalendar(props);

  const getHeaderCalendarTextStyle = useCallback(
    (date: Date) => {
      const isSaturday = date.getDay() === 6;
      const isSunday = date.getDay() === 0;
      const isHoliday = !!holidays?.[moment(date).format('YYYY-MM-DD')];
      return [
        styles.text,
        styles.headerCalendarText,
        ...(isSaturday ? [styles.saturdayTitle] : []),
        ...(isHoliday || isSunday ? [styles.holidayTitle] : []),
      ];
    },
    [holidays, customStyles]
  );

  const renderTimeLabelList = useMemo(() => {
    return (
      <FlatList
        ref={leftRef}
        onScroll={(e) => {
          const { y } = e.nativeEvent.contentOffset;
          rightRef.current?.scrollToOffset({
            animated: false,
            offset: y,
          });
        }}
        showsVerticalScrollIndicator={false}
        maxToRenderPerBatch={60}
        scrollEnabled={!isCanvas}
        data={Object.keys(durationMapping)}
        renderItem={({ item: timeLabel, index }) => (
          <View key={timeLabel}>
            {index - 1 === initializeTimeIndex ? (
              <View style={styles.timeDivider}></View>
            ) : (
              <></>
            )}
            <View
              style={[styles.timeLabelBox]}
              nativeID="booking-calendar-time-label"
            >
              <Text style={[styles.text, styles.headerCalendarText]}>
                {timeLabel}
              </Text>
            </View>
          </View>
        )}
      />
    );
  }, [durationMapping, lineHeight]);

  const renderBoxContent = useCallback(
    ({ status }: { status: 'available' | 'fewAvailable' | 'notAvailable' }) => (
      <Text
        style={[
          styles.text,
          styles.contentCalendarText,
          {
            color:
              status === 'available'
                ? customStyles.availableColor
                : status === 'fewAvailable'
                ? customStyles.fewAvailableColor
                : customStyles.notAvailableColor,
          },
        ]}
      >
        {status === 'available' ? '○' : status === 'fewAvailable' ? '△' : '×'}
      </Text>
    ),
    [customStyles]
  );
  const renderRowTime = useCallback(
    ({
      timeValues,
    }: {
      timeValues: Record<string, BindingItemTypeFormatted[]>;
    }) => {
      return (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {Array(numberOfDaysDisplayed)
            .fill(0)
            .map((_, i) => {
              const date = moment(beginDate).add(i, 'day');
              const items = timeValues[date.format('YYYY-MM-DD')];
              // if we have multiple item, sort by updatedAt desc
              let firstItem = items?.[0];
              switch (sortBy) {
                case 'availableStockDesc':
                  firstItem = items?.sort(
                    (a, b) => b.stockAvailable - a.stockAvailable
                  )?.[0];
                  break;
                case 'availableStockAsc':
                  firstItem = items?.sort(
                    (a, b) => a.stockAvailable - b.stockAvailable
                  )?.[0];
                  break;
                case 'updatedTimeDesc':
                  firstItem = items?.sort((a, b) =>
                    moment(b.updatedAt).diff(a.updatedAt)
                  )?.[0];
                  break;
                case 'updatedTimeAsc':
                  firstItem = items?.sort((a, b) =>
                    moment(a.updatedAt).diff(b.updatedAt)
                  )?.[0];
                  break;
                case 'createdTimeDesc':
                  firstItem = items?.sort((a, b) =>
                    moment(b.createdAt).diff(a.createdAt)
                  )?.[0];
                  break;
                case 'createdTimeAsc':
                  firstItem = items?.sort((a, b) =>
                    moment(a.createdAt).diff(b.createdAt)
                  )?.[0];
                  break;
                default:
                  break;
              }
              if (!firstItem) {
                return (
                  <View key={i} style={[styles.boxContentContainer]}></View>
                );
              }
              const stockAvailable = sumAvailableStock
                ? items.reduce((acc, item) => acc + item.stockAvailable, 0)
                : firstItem.stockAvailable;
              const isAvailable =
                stockAvailable > firstItem.minimumStockAvailable;
              const isFewAvailable = firstItem && firstItem.stockAvailable > 0;
              if (firstItem) {
                return (
                  <TouchableOpacity
                    key={i}
                    style={[styles.boxContentContainer]}
                    onPress={() => {
                      if (firstItem) {
                        handlePress(firstItem.id);
                      }
                    }}
                  >
                    {renderBoxContent({
                      status: isAvailable
                        ? 'available'
                        : isFewAvailable
                        ? 'fewAvailable'
                        : 'notAvailable',
                    })}
                  </TouchableOpacity>
                );
              }
              return (
                <View key={i} style={[styles.boxContentContainer]}>
                  {renderBoxContent({
                    status: isAvailable
                      ? 'available'
                      : isFewAvailable
                      ? 'fewAvailable'
                      : 'notAvailable',
                  })}
                </View>
              );
            })}
        </View>
      );
    },
    [numberOfDaysDisplayed, handlePress, beginDate]
  );

  const renderHeaderCalendar = useMemo(() => {
    return (
      <View
        style={styles.headerCalendarContainer}
        nativeID={'booking-calendar-header-calendar'}
      >
        {Array(numberOfDaysDisplayed)
          .fill(0)
          .map((_, i) => {
            const date = moment(beginDate).add(i, 'day');
            const dateInMonth = date.get('date');
            const dayLocale = dayNameSort[date.get('day')];
            return (
              <View key={i} style={styles.headerCalendarDayBox}>
                <Text style={getHeaderCalendarTextStyle(date.toDate())}>
                  {dayLocale}
                </Text>
                <Text style={getHeaderCalendarTextStyle(date.toDate())}>
                  {dateInMonth}
                </Text>
              </View>
            );
          })}
      </View>
    );
  }, [numberOfDaysDisplayed, beginDate, getHeaderCalendarTextStyle, boxWidth]);

  const renderTimeItems = useMemo(
    () =>
      isCanvas ? (
        <View>
          {Object.entries(durationMapping).map(
            ([timeLabel, timeValues], index) => {
              return (
                <View key={timeLabel}>
                  {index - 1 === initializeTimeIndex ? (
                    <View style={styles.timeDivider}></View>
                  ) : (
                    <></>
                  )}
                  {renderRowTime({ timeValues })}
                </View>
              );
            }
          )}
        </View>
      ) : (
        <FlatList
          ref={rightRef}
          onScroll={(e) => {
            const { y } = e.nativeEvent.contentOffset;
            leftRef.current?.scrollToOffset({
              animated: false,
              offset: y,
            });
          }}
          showsVerticalScrollIndicator={false}
          maxToRenderPerBatch={60}
          scrollEnabled={!isCanvas}
          data={Object.keys(durationMapping)}
          renderItem={({ item, index }) => {
            const timeValues = durationMapping[item];
            const key = item + Object.keys(timeValues)?.[0];
            return (
              <View key={key}>
                {index - 1 === initializeTimeIndex ? (
                  <View style={styles.timeDivider}></View>
                ) : (
                  <></>
                )}
                {renderRowTime({ timeValues })}
              </View>
            );
          }}
        />
      ),
    [durationMapping]
  );

  return (
    <View nativeID={`booking-calendar-${props.id}`} style={[styles.container]}>
      {/* Headers */}
      <HeaderTitle
        beginDate={beginDate}
        onNextWeek={onNextPage}
        onPreviousWeek={onPreviousPage}
        titleStyle={[styles.text, { fontWeight: 'bold' }]}
        iconSize={props.attributes.fontSize}
      />

      <View nativeID="booking-calendar-body" style={[styles.containerBody]}>
        {/* Left side - synched vertical scroll - used to display time label - used scrollView to scroll to current time*/}
        <View
          style={{ width: boxWidth * 2, position: 'relative' }}
          nativeID="booking-calendar-content-left"
        >
          {/* Blank box */}
          <View style={[styles.blankBox]}></View>
          {renderTimeLabelList}
        </View>
        {/* Right side - included the day header, box item */}
        {numberOfDaysDisplayed > dayNameSort.length ? (
          <ScrollView
            nativeID="booking-calendar-content-right"
            horizontal
            contentContainerStyle={{ display: 'flex', flexDirection: 'column' }}
            nestedScrollEnabled
            showsHorizontalScrollIndicator={false}
          >
            {renderHeaderCalendar}
            {renderTimeItems}
          </ScrollView>
        ) : (
          <View>
            {renderHeaderCalendar}
            {renderTimeItems}
          </View>
        )}
      </View>
    </View>
  );
};

export default BookingCalendar;
