export default {
  name: 'LineLogin',
  displayName: {
    en: 'Line Login',
    ja: 'Line Login',
  },
  defaultWidth: 160,
  defaultHeight: 36,
  resizeX: true,
  props: [
    {
      name: 'type',
      displayName: {
        en: 'Type',
        ja: 'タイプ',
      },
      type: 'text',
      default: 'contained',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.button.type.text',
            value: 'text',
          },
          {
            label: 'canvas.menuRight.elementTab.button.text.outline',
            value: 'outlined',
          },
          {
            label: 'canvas.menuRight.elementTab.button.text.button',
            value: 'contained',
          },
        ],
      },
    },
    {
      name: 'text',
      displayName: {
        en: 'Text',
        ja: 'テキスト',
      },
      type: 'text',
      default: 'Button',
      styles: {
        fontFamily: '@body',
        fontWeight: '600',
      },
    },
    {
      name: 'isCheckFriendship',
      displayName: {
        en: 'Check LINE friendship',
        ja: '友だちチェック',
      },
      type: 'boolean',
      plan: ['2'],
      default: false,
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'Button',
        ja: 'Button',
      },
    },
    {
      name: 'successActions',
      displayName: {
        en: 'Actions when Getting LINE friendship is successful and added friend',
        ja: '友だち追加済みで、ブロックしていない場合のアクション',
      },
      type: 'action',
    },
    {
      name: 'failedActions',
      displayName: {
        en: "Actions when Line friendship is failed,blocked or haven't added friend",
        ja: '友だち未追加/ブロックしている場合、または取得失敗場合のアクション',
      },
      type: 'action',
    },
  ],
};
