export default {
  name: 'SimpleList',
  displayName: {
    en: 'Simple List',
    ja: 'ベーシック',
  },
  icon: './icon.png',
  defaultWidth: 600,
  defaultHeight: 232,
  resizeX: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
    },
    {
      name: 'sortList',
      displayName: {
        en: 'Sorting',
        ja: '並び替え',
      },
      type: 'sort',
    },
    {
      name: 'maximumItems',
      displayName: {
        en: 'Maximum number of items',
        ja: '上限',
      },
      type: 'maximumItems',
    },
    {
      name: 'scrollActive',
      platform: 'mobile',
      displayName: {
        en: 'Scroll Active',
        ja: 'スクロールのアクティブ化',
      },
      type: 'boolean',
    },
    {
      name: 'rowCount',
      platform: 'web',
      displayName: {
        en: 'Rows',
        ja: 'Rows',
      },
      type: 'number',
      default: 3,
      control: {
        type: 'menu',
        options: [
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5',
            value: 5,
          },
          {
            label: '6',
            value: 6,
          },
          {
            label: '7',
            value: 7,
          },
          {
            label: '8',
            value: 8,
          },
          {
            label: '9',
            value: 9,
          },
          {
            label: '10',
            value: 10,
          },
        ],
      },
    },
    {
      name: 'dividerType',
      displayName: {
        en: 'Divider',
        ja: '境界線',
      },
      type: 'text',
      default: 'inset',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.divider.none',
            value: 'none',
          },
          {
            label: 'canvas.menuRight.elementTab.divider.full',
            value: 'full',
          },
        ],
      },
    },
    {
      name: 'dividerColor',
      displayName: {
        en: 'Divider Color',
        ja: 'Divider Color',
      },
      type: 'color',
      default: '#e0e0e0',
    },
    {
      name: 'onPress',
      displayName: {
        en: 'List',
        ja: 'リスト',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
      tabType: 'click-flow',
    },
  ],
  childComponents: [
    {
      name: 'title',
      displayName: {
        en: 'Title',
        ja: 'タイトル',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Title',
          styles: {
            fontFamily: '@body',
            color: '#212121',
            fontWeight: 'normal',
          },
        },
        {
          name: 'titleLineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.multiLine',
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'subTitle',
      displayName: {
        en: 'Sub Title',
        ja: 'サブタイトル',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Subtitle',
          styles: {
            fontFamily: '@body',
            color: '#757575',
            fontWeight: 'normal',
          },
        },
        {
          name: 'subtitleLineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.multiLine',
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'subTitle2',
      displayName: {
        en: 'Sub Title 2',
        ja: 'サブタイトル2',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Subtitle 2',
          styles: {
            fontFamily: '@body',
            color: '#757575',
            fontWeight: 'normal',
          },
        },
        {
          name: 'subtitle2LineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.multiLine',
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'leftSection',
      displayName: {
        en: 'Left Section',
        ja: '左セクション',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'type',
          displayName: {
            en: 'Type',
            ja: 'タイプ',
          },
          type: 'text',
          default: 'avatar',
          control: {
            type: 'menu',
            options: [
              {
                label: 'Icon',
                value: 'icon',
              },
              {
                label:
                  'canvas.menuRight.elementTab.list.basic.leftSection.avatar',
                value: 'avatar',
              },
              {
                label: 'Image',
                value: 'image',
              },
            ],
          },
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'add',
          enabled: {
            type: 'icon',
          },
        },
        {
          name: 'iconColor',
          displayName: {
            en: 'Icon Color',
            ja: 'アイコン色',
          },
          type: 'color',
          default: '#ccc',
          enabled: {
            type: 'icon',
          },
        },
        {
          name: 'imageUrl',
          displayName: {
            en: 'Image Source',
            ja: '画像ソース',
          },
          type: 'image',
          enabled: {
            type: ['image', 'avatar'],
          },
        },
      ],
    },
    {
      name: 'rightSection',
      displayName: {
        en: 'Right Section',
        ja: '右セクション',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'iconType',
          displayName: {
            en: 'Type',
            ja: 'タイプ',
          },
          type: 'text',
          default: 'icon',
          control: {
            type: 'menu',
            options: [
              {
                label: 'Icon',
                value: 'icon',
              },
              {
                label: 'Toggle',
                value: 'toggle',
              },
            ],
          },
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'add',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'iconColor',
          displayName: {
            en: 'Icon Color',
            ja: 'アイコン色',
          },
          type: 'color',
          default: '#ccc',
          enabled: {
            iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          },
        },
        {
          name: 'onPress',
          displayName: 'Actions',
          type: 'action',
          role: 'listItem',
          reference: 'items',
          // enabled: {
          //   iconType: ['toggle', 'CLICK_INTERNAL_!previous'],
          // },
        },
        {
          name: 'input',
          displayName: {
            en: 'What does this toggle?',
            ja: 'データを選択してください',
          },
          type: 'boolean',
          role: 'autosaveInput',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'activeIcon',
          displayName: {
            en: 'Active Icon',
            ja: 'アクティブ時のアイコン',
          },
          type: 'icon',
          default: 'check-box',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'activeColor',
          displayName: {
            en: 'Active Color',
            ja: 'アクティブ時の色',
          },
          type: 'color',
          default: '@primary',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'inactiveIcon',
          displayName: {
            en: 'Inactive Icon',
            ja: '非アクティブ時のアイコン',
          },
          type: 'icon',
          default: 'check-box-outline-blank',
          enabled: {
            iconType: 'toggle',
          },
        },
        {
          name: 'inactiveColor',
          displayName: {
            en: 'Inactive Color',
            ja: '非アクティブ時の色',
          },
          type: 'color',
          default: '#bbb',
          enabled: {
            iconType: 'toggle',
          },
        },
        // {
        //   name: 'activeActions',
        //   displayName: 'Additional actions only when toggle is turned ON',
        //   type: 'action',
        //   enabled: {
        //     iconType: 'toggle',
        //   },
        // },
        // {
        //   name: 'inactiveActions',
        //   displayName: 'Additional actions only when toggle is turned OFF',
        //   type: 'action',
        //   enabled: {
        //     iconType: 'toggle',
        //   },
        // },
      ],
    },
    // {
    //   name: 'background',
    //   displayName: 'Background',
    //   props: [
    //     {
    //       name: 'enabled',
    //       type: 'boolean',
    //       default: false,
    //     },
    //     {
    //       name: 'backgroundColor',
    //       displayName: 'Background Color',
    //       type: 'color',
    //       default: '#FFFFFF',
    //     },
    //     {
    //       name: 'border',
    //       displayName: 'Border',
    //       type: 'boolean',
    //       default: true,
    //     },
    //     {
    //       name: 'borderColor',
    //       displayName: 'Border Color',
    //       type: 'color',
    //       default: '#e0e0e0',
    //       enabled: {
    //         border: true,
    //       },
    //     },
    //     {
    //       name: 'borderSize',
    //       displayName: 'Border Size',
    //       type: 'number',
    //       default: 1,
    //       control: {
    //         type: 'slider',
    //         max: 3,
    //         min: 1,
    //       },
    //       enabled: {
    //         border: true,
    //       },
    //     },
    //     {
    //       name: 'rounding',
    //       displayName: 'Rounding',
    //       type: 'number',
    //       default: 4,
    //       control: {
    //         type: 'slider',
    //         max: 60,
    //         min: 0,
    //       },
    //     },
    //     {
    //       name: 'shadow',
    //       displayName: 'Shadow',
    //       type: 'boolean',
    //       default: true,
    //     },
    //   ],
    // },
  ],
};
