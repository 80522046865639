export default {
  name: 'PayPay',
  displayName: {
    en: 'PayPay',
    ja: 'PayPay',
  },
  defaultWidth: 155,
  defaultHeight: 44,
  resizeX: true,
  props: [
    {
      namespace: 'fincode',
      name: 'settings',
      displayName: {
        en: 'Settings',
        ja: 'Settings',
      },
      type: 'payment',
    },
    {
      name: 'testModeEnabled',
      displayName: {
        en: 'Test Mode',
        ja: 'テストモード',
      },
      type: 'boolean',
    },
    {
      name: 'paymentAmount',
      displayName: {
        en: 'Payment Amount',
        ja: '支払い金額',
      },
      type: 'number',
    },
    {
      name: 'fincodeOrderId',
      displayName: 'ClickPay ID',
      type: 'string',
      role: 'formValue',
    },
    {
      name: 'successActions',
      displayName: {
        en: 'Actions when PayPay Payment is paid successful',
        ja: '決済成功時のアクション',
      },
      type: 'action',
    },
    {
      name: 'failedActions',
      displayName: {
        en: 'Actions when PayPay Payment is paid failed',
        ja: '決済失敗時のアクション',
      },
      type: 'action',
    },
  ],
  childComponents: [
    {
      name: 'submitButton',
      displayName: {
        en: 'Submit Button',
        ja: '送信ボタン',
      },
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
        },
        {
          name: 'type',
          displayName: {
            en: 'Type',
            ja: 'タイプ',
          },
          type: 'text',
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.button.type.text',
                value: 'text',
              },
              {
                label: 'canvas.menuRight.elementTab.button.text.outline',
                value: 'outlined',
              },
              {
                label: 'canvas.menuRight.elementTab.button.text.button',
                value: 'contained',
              },
            ],
          },
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
        },
        {
          name: 'upperCase',
          displayName: {
            en: 'Uppercase',
            ja: '大文字',
          },
          type: 'boolean',
        },
      ],
    },
  ],
};
