import { API_ENDPOINTS } from '@common/constants/apiEndpoint';
import axiosInstance from '@common/services/axiosIntance/axiosService';

type GetChatHistoryRequest = {
  threadId: string;
  apiKey: string | number; // App variable keys
  assistantId: string;
};
export type IMessage = {
  id: string;
  senderType: 'user' | 'ai' | 'error';
  messageType: 'text' | 'url';
  message: string;
  createdAt: number; // unix time
};
type GetGptMessageHistoryResponse = IMessage[];
export const useGptConversationAction = (appId?: string) => {
  const getMessageHistory = async (data: GetChatHistoryRequest) => {
    try {
      const { apiKey, assistantId, threadId } = data;
      const response = await axiosInstance({
        url: API_ENDPOINTS.GPT_CONVERSATION_CHAT_HISTORY,
        method: 'POST',
        data: { apiKey, assistantId, threadId, appId },
      }).then((r) => r.data as GetGptMessageHistoryResponse);
      return response;
    } catch (e: any) {
      console.error('=== getMessageHistory error===', e);
      return [];
    }
  };
  return { getMessageHistory };
};
export type GptConversationActionType = ReturnType<
  typeof useGptConversationAction
>;
