import api from '@common/configs/api';
import axios from 'axios';
import Compressor from 'compressorjs';
import _, { floor, get, pick } from 'lodash';
import moment from 'moment';
import { uuidv4 } from './record';

const MAX_SIZE = 1000 * 200;

const scaleCanvas =
  (fileSize: number) =>
  (context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
    const oldWidth = canvas.width;
    const oldHeight = canvas.height;
    const bit = fileSize / (oldHeight * oldWidth);
    const newWidth = floor(
      Math.sqrt((MAX_SIZE * oldWidth) / (bit * oldHeight))
    );
    const newHeight = floor((newWidth * oldHeight) / oldWidth);
    canvas.width = newWidth;
    canvas.height = newHeight;
    context.scale(newWidth / oldWidth, newHeight / oldHeight);
  };

const resizeFile = (file: File) => {
  if (file.size < MAX_SIZE || file.type === 'image/gif') return file;

  let qualityNum = 0.5;
  const resultSize = file.size * qualityNum;
  if (resultSize > MAX_SIZE) {
    qualityNum = MAX_SIZE / file.size;
  }
  const quality = floor(qualityNum, 1);

  return new Promise(
    (resolve) =>
      new Compressor(file, {
        success: resolve,
        quality,
        convertSize: Infinity,
        beforeDraw: (context, canvas) => {
          if (file.type === 'image/png') {
            scaleCanvas(file.size)(context, canvas);
          }
        },
      })
  ).then((newFile: any) => {
    if (newFile.size > MAX_SIZE) {
      return new Promise(
        (resolve) =>
          new Compressor(newFile, {
            success: resolve,
            convertSize: Infinity,
            quality,
            beforeDraw: scaleCanvas(newFile.size),
          })
      );
    }
    return newFile;
  });
};

export const compression = async (file: any): Promise<any> => {
  try {
    const newFile = await resizeFile(file);

    return newFile;
  } catch (e) {
    console.log('error', e);
  }
};

export const imageTypeValid = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
];

export const getFileExt = (filename = '') => {
  const array = filename.split('.');
  return _.last(array);
};

type OtpProps = {
  quality: number;
  setPending: (value: boolean) => any;
  setError: (value: string) => any;
  imageUpload?: boolean;
};

export class ImagePicker {
  static showImagePicker = (
    {
      quality,
      setPending,
      imageUpload,
      setError,
      attributes,
      imageCompress,
    }: OtpProps | any,
    callback: any
  ) => {
    const isCapture = get(attributes, 'field.imageSource')
      ? get(attributes, 'field.imageSource')
      : get(attributes, 'imageSource');

    let hasCleanedUp = false;
    let changed = false;
    let input: any = document.createElement('input');
    input.type = 'file';
    input.capture = 'camera';
    input.style.position = 'fixed';
    input.style.zIndex = '10000';
    input.style.top = '-200px';
    input.style.left = '20px';
    document.body.appendChild(input);

    if (isCapture !== 'takePhoto') {
      input.removeAttribute('capture');
    }

    const handleUpload = async (file: any) => {
      const fileExt = getFileExt(file.name || '');

      const fileType = file.type;

      const {
        data: { url: url_v1, keyPath },
      }: any = await api({
        method: 'post',
        url: `sharefiles`,
        data: {
          fileExt: fileExt || file.name.split('.').pop(),
          fileType,
        },
      });

      const newFile =
        imageTypeValid.includes(file.type) && imageCompress === true
          ? await compression(file)
          : file;

      await axios.put(url_v1, newFile, {
        headers: { 'Content-Type': file.type },
      });

      const {
        data: { url },
      }: { data: { url: string } } = await api({
        method: 'get',
        url: `sharefiles`,
        params: {
          pathObject: keyPath,
        },
      });

      return { url: url, file: newFile };
    };

    const cleanup = (setPending: (params: boolean) => any) => {
      if (hasCleanedUp) {
        return;
      }
      hasCleanedUp = true;

      input.removeEventListener('change', changeListener);
      input.removeEventListener('focus', focusHandler);
      document.body.removeChild(input);
      setPending(false);
    };

    const handleReadFile = (url: string, file: any) => {
      let reader = new FileReader();

      reader.onload = () => {
        callback({
          url,
          filename: file.name,
          size: file.size,
          id: uuidv4(),
          type: file.type,
        });

        window.setTimeout(() => cleanup(setPending), 0);
      };

      reader.readAsDataURL(file);
    };

    const changeListener = async (e: any) => {
      let file = e.target.files[0];
      changed = true;

      if (file) {
        const handledFile = await handleUpload(file);

        const invalid = !imageUpload
          ? true
          : imageTypeValid.includes(file.type);

        if (handledFile.url && invalid) {
          handleReadFile(handledFile.url, handledFile.file);
        } else {
          cleanup(setPending);
          setError('file is not valid formatter, please try !');
        }
      } else {
        cleanup(setPending);
      }
    };

    const focusHandler = () => {
      window.setTimeout(() => {
        if (changed) {
          return;
        }
        cleanup(setPending);
      }, 1000);
    };

    input.addEventListener('change', changeListener);

    window.setTimeout(() => {
      input.focus();

      input.addEventListener('focus', focusHandler);

      input.click();
    }, 0);
  };
}

export const FilePicker = {
  showFilePicker: (callback: any) => {
    return ImagePicker.showImagePicker({}, callback);
  },
};
export type IFileUploadDocument = {
  fileCopyUri: string;
  filename: string;
  size: number;
  type: string;
  uri: string;
};

export const uploadFileDocument = async (file: any) => {
  const {
    data: { url, keyPath },
  }: any = await api({
    method: 'post',
    url: `sharefiles`,
    data: {
      fileExt: file._data.name.split('.').pop(),
      fileType: file._data.type,
    },
  });
  await fetch(url, { method: 'PUT', body: file });

  const {
    data: { url: fileUrl },
  }: { data: { url: string } } = await api({
    method: 'get',
    url: `sharefiles`,
    params: {
      pathObject: keyPath,
    },
  });

  return {
    ...pick(file._data, ['filename', 'size', 'type', 'name', 'blobId']),
    url: fileUrl,
  };
};

export const generateFile = async (base64: any) => {
  try {
    const res = await fetch(base64);
    const blob = await res.blob();
    return blob;
  } catch (error) {
    alert('Error occurred while handle data.');
  }
};
