import { ActionStatus } from '@common/constants/shared';
import { IAction } from '@common/types/';
import { MetadataAction } from '@common/types/action';
import { get } from 'lodash';
import { Platform } from 'react-native';
import { ActionResponse } from '../excuteAction';

const downloadFileWeb = async ({
  fileExt,
  fileName,
  url,
}: {
  url: string;
  fileExt: string;
  fileName: string;
}) => {
  const filename = `${fileName}${fileExt ? `.${fileExt}` : ''}`;
  return fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
      // or you know, something with better UX...
      // alert('your file has downloaded!');
    })
    .catch((error) => {
      console.error(error);
      window.open(url, '_blank');
    });
};

export const downloadFile = async (
  action: Pick<IAction, 'response'>,
  metadata?: MetadataAction
): Promise<ActionResponse> => {
  const url = get(action, 'response.url', '');
  const fileExt = get(action, 'response.fileExt', '');
  const fileName =
    get(action, 'response.fileName', null) || Date.now().toString();

  if (typeof url !== 'string' || !url) {
    return {
      status: ActionStatus.FAILED,
    };
  }

  try {
    if (Platform.OS === 'web') {
      await downloadFileWeb({ url, fileExt, fileName });
      return {
        status: ActionStatus.SUCCESS,
      };
    }
    return {
      status: ActionStatus.FAILED,
      message: 'This device is not supported yet!',
    };
  } catch (error: any) {
    return {
      status: ActionStatus.FAILED,
      message: error.message,
    };
  }
};
