import { MarketplaceComponentConfig } from '../../types/config.type';
import { GptConversationServiceTypeEnum } from '@common/constants/gpt-conversation.constant';

const DEFAULT_OPEN_AI_MODEL = 'gpt-4o-mini';
const config: MarketplaceComponentConfig = {
  name: 'GptConversation',
  displayName: {
    en: 'Gpt Conversation',
    ja: 'AIチャットボット',
  },
  icon: './icon.png',
  defaultWidth: 375,
  defaultHeight: 600,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'value',
      displayName: 'OpenAI Thread ID',
      type: 'string',
      role: 'formValue',
    },
    {
      name: 'apiUrl',
      displayName: {
        en: 'API URL',
        ja: 'API URL',
      },
      enabled: {
        serviceType: [GptConversationServiceTypeEnum.dify],
      },
      type: 'text',
      placeholder: 'https://api.dify.ai/v1/chat-messages',
    },
    {
      name: 'serviceType',
      displayName: {
        en: 'Service',
        ja: 'サービス',
      },
      type: 'text',
      default: GptConversationServiceTypeEnum.openAi,
      control: {
        type: 'menu',
        options: [
          {
            label: 'Dify',
            value: GptConversationServiceTypeEnum.dify,
          },
          {
            label: 'OpenAI',
            value: GptConversationServiceTypeEnum.openAi,
          },
          {
            label: 'OpenAI Assistants',
            value: GptConversationServiceTypeEnum.openAiAssistant,
          },
        ],
      },
    },
    {
      name: 'assistantId',
      displayName: {
        en: 'Assistant ID',
        ja: 'Assistant ID',
      },
      placeholder: 'Assistant ID',
      type: 'text',
      enabled: {
        serviceType: [GptConversationServiceTypeEnum.openAiAssistant],
      },
    },
    {
      name: 'threadId',
      displayName: {
        en: 'Thread ID',
        ja: 'Thread ID',
      },
      placeholder: 'Thread ID',
      type: 'text',
      enabled: {
        serviceType: [GptConversationServiceTypeEnum.openAiAssistant],
      },
    },
    {
      name: 'apiKey',
      displayName: {
        en: 'API Key',
        ja: 'APIキー',
      },
      placeholder: 'xxxx-xxxx-xxxx',
      type: 'text',
      useAppVariable: true,
    },
    {
      name: 'openAiModel',
      displayName: {
        en: 'OpenAI Model',
        ja: 'モデル',
      },
      type: 'text',
      default: DEFAULT_OPEN_AI_MODEL,
      enabled: {
        serviceType: [GptConversationServiceTypeEnum.openAi],
      },
    },
    {
      name: 'initPrompt',
      displayName: {
        en: 'Prompt',
        ja: 'プロンプト',
      },
      type: 'text',
      default: '',
      enabled: {
        serviceType: [GptConversationServiceTypeEnum.openAi],
      },
    },
    {
      name: 'initMessage',
      displayName: {
        en: 'Initial message',
        ja: '初期メッセージ',
      },
      type: 'text',
      default:
        'こんにちは！私はあなたのお手伝いをするAIチャットボットです。何かお困りのことや質問があれば、ぜひ教えてくださいね！',
    },
    {
      name: 'placeholder',
      displayName: {
        en: 'Input Placeholder',
        ja: '質問のプレースホルダー',
      },
      type: 'text',
      default: 'メッセージ',
      disableDynamicValues: true,
    },
    {
      name: 'backgroundColor',
      displayName: {
        en: 'Background Color',
        ja: '背景色',
      },
      type: 'color',
      default: '#34A0DD',
    },
    {
      name: 'fontSize',
      type: 'fontSize',
    },
    {
      name: 'fontFamily',
      type: 'fontFamily',
    },
  ],
  childComponents: [
    {
      name: 'advanceSetting',
      displayName: {
        en: 'Advanced Settings',
        ja: '詳細設定',
      },
      props: [
        {
          name: 'aiName',
          displayName: {
            en: 'AI Name',
            ja: 'AIの名前',
          },
          type: 'text',
          default: 'Jack',
        },
        {
          name: 'username',
          displayName: {
            en: 'User Name',
            ja: 'ユーザー名',
          },
          type: 'text',
          default: 'あなた',
        },
        {
          name: 'userColor',
          type: 'color',
          displayName: {
            en: 'User Color',
            ja: 'ユーザー側のテキスト色',
          },
          default: '#000',
        },
        {
          name: 'aiColor',
          type: 'color',
          displayName: {
            en: 'AI color',
            ja: 'AI側のテキスト色',
          },
          default: '#8876ff',
        },
      ],
    },
    {
      name: 'sendButton',
      displayName: {
        en: 'Submit Button',
        ja: '送信ボタン',
      },
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: '支払い',
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'add',
        },
        {
          name: 'color',
          type: 'color',
          displayName: {
            en: 'Text color',
            ja: 'ボタンのテキスト色',
          },
          default: '#000',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: 'ボタンの色',
          },
          type: 'color',
          default: '#fff',
        },
      ],
    },
  ],
};

export default config;
