import { IAppInfo } from '@common/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  app?: Pick<
    IAppInfo,
    | 'metadata'
    | 'id'
    | 'platform'
    | 'appId'
    | 'APP_NAME'
    | 'APP_VERSION'
    | 'icon'
    | 'name'
    | 'primaryColor'
    | 'isConnectUnivapay'
    | 'shareDatabaseAppId'
  >;
  currentLocation: { lat: number; lng: number } | null;
  locationError: any;
  error: Record<string, any>;
};

const initialState: State = {
  currentLocation: null,
  locationError: null,
  error: {
    messages: [],
    isError: false,
  },
};

const appSlice = createSlice({
  name: 'appInfo',
  initialState: initialState,
  reducers: {
    loadApp: (state: State, action: PayloadAction<IAppInfo>) => {
      if (action.payload) {
        state.app = action.payload;
      }
    },
    checkLocationSuccess: (
      state: State,
      action: PayloadAction<{ lat: number; lng: number }>
    ) => {
      if (!action.payload) return;

      state.currentLocation = action.payload;
    },
    checkLocationSuccessWithoutRerender: (
      state: State,
      action: PayloadAction<{ lat: number; lng: number }>
    ) => {
      if (!action.payload) return;
      if (state.currentLocation) {
        state.currentLocation.lat = action.payload.lat;
        state.currentLocation.lng = action.payload.lng;
      } else {
        state.currentLocation = action.payload;
      }
    },
    checkLocationError: (state: State, action: PayloadAction<any>) => {
      state.locationError = action.payload;
    },
    setError: (state: State, action: PayloadAction<any>) => {
      state.error.isError = true;
      state.error.messages = [...state.error.messages, action.payload];
    },
    resetError: (state: State) => {
      state.error = initialState.error;
    },
  },
});

export const {
  loadApp,
  checkLocationSuccess,
  checkLocationSuccessWithoutRerender,
  checkLocationError,
  setError,
  resetError,
} = appSlice.actions;
export default appSlice.reducer;
