import queryString from 'query-string';
import React, { useEffect } from 'react';
import { KeyboardAvoidingView, Platform, View } from 'react-native';
import { CanvasView } from './components/CanvasView';
import { GroupMessage } from './components/GroupList';
import ImagePreviewModal from './components/ImagePreviewModal';
import { MessageHistory } from './components/MessageHistory';
import { ChatComponentProvider, useChatComponentContext } from './context';
import {
  isPCView,
  PC_WIDTH_GROUP_MESSAGE_VIEW,
  useChatComponent,
  UseChatComponentProps,
} from './hook';
import { chatComponentStyles } from './style';
import { ChatComponentProps } from './type';

const PreviewContent = (props: ChatComponentProps & UseChatComponentProps) => {
  const context = useChatComponentContext();
  const styled = chatComponentStyles(props);

  if (isPCView(props.width)) {
    return (
      <View style={styled.pcContainer} nativeID="Preview">
        <View
          style={{
            width: PC_WIDTH_GROUP_MESSAGE_VIEW,
          }}
        >
          <GroupMessage
            messages={context.groupMessages}
            onSelectGroup={props.onSelectGroup}
            unreadMessageStatus={context.unreadMessageStatus}
          />
        </View>
        <View
          style={{
            width: props.width - PC_WIDTH_GROUP_MESSAGE_VIEW - 8,
          }}
        >
          <MessageHistory
            messages={context.messageHistories}
            groupName={context.selectedGroup?.groupName || ''}
            context={context}
            hookActions={props}
          />
        </View>
      </View>
    );
  }

  if (context.selectedGroup) {
    return (
      <MessageHistory
        showBackButton
        messages={context.messageHistories}
        groupName={context.selectedGroup?.groupName || ''}
        context={context}
        hookActions={props}
      />
    );
  }

  return (
    <GroupMessage
      messages={context.groupMessages}
      onSelectGroup={props.onSelectGroup}
      unreadMessageStatus={context.unreadMessageStatus}
    />
  );
};

const Preview = (props: ChatComponentProps) => {
  const context = useChatComponentContext();
  const chatProps = useChatComponent({
    ...props,
  });
  const styled = chatComponentStyles(props);

  useEffect(() => {
    chatProps.fetchGroupList();
  }, []);

  useEffect(() => {
    // TODO: implement realtime
    const intervalFetchMessages = setInterval(() => {
      chatProps.fetchGroupList();
    }, 10000);
    return () => {
      clearInterval(intervalFetchMessages);
    };
  }, []);

  useEffect(() => {
    // TODO: implement realtime
    if (context.selectedGroup) {
      const intervalFetchMessages = setInterval(() => {
        chatProps.fetchMessageHistory({
          groupId: context.selectedGroup!.id,
          pagination: context.messageHistoriesPagination,
        });
      }, 10000);
      return () => {
        clearInterval(intervalFetchMessages);
      };
    }
  }, [context.selectedGroup]);

  return (
    <View style={styled.container} nativeID="Preview">
      {Platform.OS === 'web' ? (
        <PreviewContent {...props} {...chatProps} />
      ) : (
        <KeyboardAvoidingView behavior={'padding'} keyboardVerticalOffset={120}>
          <PreviewContent {...props} {...chatProps} />
        </KeyboardAvoidingView>
      )}
      <ImagePreviewModal
        imageUrl={context.selectedImage?.url || ''}
        onClose={() => {
          context.setSelectedImage(null);
        }}
        onDownload={() => {
          if (context.selectedImage) {
            props.chatActions.onDownloadFile(context.selectedImage);
          }
        }}
        visible={!!context.selectedImage}
      />
    </View>
  );
};

export default function (props: ChatComponentProps) {
  const isCanvas =
    Platform.OS === 'web' &&
    !queryString.parse(window?.location?.search)?.target;

  if (isCanvas) {
    return <CanvasView {...props} />;
  }
  return (
    <ChatComponentProvider {...props}>
      <Preview {...props} />
    </ChatComponentProvider>
  );
}
